import { useContext } from '@nuxtjs/composition-api'

import { computed, toRefs, unref } from 'vue'

import { GaAction } from '@ga/ui-components/action'
import { GaCountryFlag } from '@ga/ui-components/country-flag'
import { GaImage } from '@ga/ui-components/image'

import { GaLangSwitcherListItem } from './children/list-item'

// @vue/component
export default {
  name: 'ga-app-lang-switcher',

  components: {
    GaLangSwitcherListItem,
    GaAction,
    GaImage,
    GaCountryFlag,
  },

  props: {
    /**
     * Скрывает название стора
     */
    showStore: {
      type: Boolean,
      default: true,
    },
    /**
     * Показывает все языки, включая текущий, доступные для стора
     */
    showAllLanguages: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { $gaApp } = useContext()
    const { showStore, showAllLanguages } = toRefs(props)

    const isNewFlagComponent = $gaApp.features.get('newFlagComponent')

    const currentStore = computed(
      () => $gaApp.services.app.langSwitcher.currentStore,
    )

    const availableLanguages = computed(
      () => $gaApp.services.app.langSwitcher.availableLanguages,
    )

    const switchLocale = (code) => {
      $gaApp.services.app.langSwitcher.switchLocale(code)
    }

    const allLanguages = computed(() =>
      $gaApp.services.app.langSwitcher.allLanguages.map((lang) => {
        return {
          ...lang,
          current: $gaApp.services.app.langSwitcher.isCurrentLanguage(
            lang.code,
          ),
        }
      }),
    )

    const languages = computed(() => {
      return unref(showAllLanguages)
        ? allLanguages.value
        : availableLanguages.value
    })

    const getShowDivider = (index) => index !== 0 || unref(showStore)

    return {
      currentStore,
      languages,

      switchLocale,
      isNewFlagComponent,
      getShowDivider,
    }
  },
}
