import capitalize from 'lodash/capitalize'

const REGION_TYPES = {
  CITY: 'город',
  REPUBLIC: 'республика',
  CHUVASHIA: 'Чувашия',
}

const DIRECT_WORD_ORDER_REPUBLICS = [
  'Донецкая Народная',
  'Кабардино-Балкарская',
  'Карачаево-Черкесская',
  'Луганская Народная',
  'Удмуртская',
  'Чеченская',
]

export class FormatterService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Собирает строку с домом и блоком
   * @param { string } house - номер строения с типом/без типа
   * @param { string } houseType - тип строения
   * @param { string } blockType - тип блока
   * @param { string } block - блок
   */
  composeHouse({ house, houseType, blockType, block }) {
    const addHouseType = !house?.startsWith(houseType)

    // todo: убедиться действительно ли может быть тип без house/block
    //  если нет - убрать доп. проверки
    return [
      house
        ? [addHouseType && houseType, house].filter(Boolean).join('. ')
        : null,
      block ? [blockType, block].filter(Boolean).join('. ') : null,
    ]
      .filter(Boolean)
      .join(', ')
  }

  composeRegionWithType(region, regionType) {
    let directWordOrder = true
    let separator = null
    let composeRegionType = regionType

    /*
     * region содержит regionType
     * region: Ханты-Мансийский Автономный округ - Югра, regionType: aвтономный округ
     * region: Кемеровская область - Кузбасс, regionType: область
     * */
    if (
      String(region).toLowerCase().includes(String(regionType).toLowerCase())
    ) {
      composeRegionType = null
    }

    /*
     * Города федерального значения без "город"
     * "город Москва" -> "Москва"
     * */
    if (regionType === REGION_TYPES.CITY) {
      composeRegionType = null
    }

    /*
     * Правильная очередность для республик
     * "Республика Хакасия"
     * "Чеченская Республика"
     * */
    if (
      regionType === REGION_TYPES.REPUBLIC &&
      !DIRECT_WORD_ORDER_REPUBLICS.includes(region)
    ) {
      directWordOrder = false
    }

    /* Республика с большой буквы
     * "Чеченска Республика"
     * */
    if (regionType === REGION_TYPES.REPUBLIC) {
      composeRegionType = capitalize(regionType)
    }

    /*
     * Только для Чувашии
     * region: 'Чувашская республика'
     * regionType: 'Чувашия'
     * Чувашская Республика — Чувашия
     * */
    if (regionType === REGION_TYPES.CHUVASHIA) {
      separator = '—'
    }

    return (
      directWordOrder
        ? [region, separator, composeRegionType]
        : [composeRegionType, separator, region]
    )
      .filter(Boolean)
      .join(' ')
  }

  /*
   * Адрес доставки одной строкой
   * */
  formatLocationDeliveryName(location) {
    const { city: composeCity, street, house, block, blockType } = location
    const composeHouse = this.composeHouse({
      house,
      blockType,
      block,
    })

    return [street || composeCity, composeHouse].filter(Boolean).join(', ')
  }

  /*
   * Адрес доставки в мобильном меню, пдп, фильтрах на категории
   * разделенный на дом и остальное
   * */
  formatLocationDeliveryNameDivided(location) {
    const { city: composeCity, street, house, block, blockType } = location
    const composeHouse = this.composeHouse({
      house,
      blockType,
      block,
    })

    const streetHouseSeparator =
      (street || composeCity) && composeHouse ? `,&nbsp;` : ''

    return {
      main: [street || composeCity, streetHouseSeparator].join(''),
      house: composeHouse,
    }
  }

  /*
   * Нужен в хедере на десктопе
   * разделение на house и все остальное нужно чтобы отображать в адресе ..., если адрес длинный, например:
   * Москва · Екатерины Буданов...5
   * */
  formatLocationDeliveryNameExtended(location) {
    const { city: composeCity, street, house, block, blockType } = location
    const composeHouse = this.composeHouse({
      house,
      blockType,
      block,
    })

    const streetHouseSeparator = street && composeHouse ? `,&nbsp;` : ''
    const citySeparator =
      composeCity && (street || composeHouse) ? `&nbsp;&nbsp;·&nbsp;&nbsp;` : ''

    return {
      main: [composeCity, citySeparator, street, streetHouseSeparator].join(''),
      house: composeHouse,
    }
  }

  /**
   * Список сохраненных адресов
   * @param { object } location
   */
  formatLocationAddressList(location) {
    const { street, house, houseType, block, blockType, address } = location
    const { region, regionTypeFull, city, settlement } = address

    const localityHouse = this.composeHouse({
      house,
      houseType,
      block,
      blockType,
    })
    const regionWithType = this.composeRegionWithType(region, regionTypeFull)

    const items = [settlement, city, regionWithType]
    const uniqueItems = [...new Set(items.filter(Boolean))]

    const localityMain = localityHouse || street ? street : uniqueItems.shift()
    const area = uniqueItems.shift()

    const localitySeparator = localityMain && localityHouse ? ',&nbsp;' : ''

    return {
      area,
      localityMain: [localityMain, localitySeparator].join(''),
      localityHouse,
    }
  }

  /**
   * Собирает из адреса строку с улицей и домом
   * - Инпут улицы в форме редактирования адреса
   * - Нотификация при редактировании/удалении/смене дефолтного адреса
   * @param { object } location
   * @returns {{result: string, itemsCount: number}}
   */
  formatStreetHouse(location) {
    const { street, streetType, house, houseType, block, blockType } =
      location || {}

    const composedHouse = this.composeHouse({
      house,
      houseType,
      block,
      blockType,
    })

    const items = [
      [streetType, street].filter(Boolean).join('. '),
      composedHouse,
    ].filter(Boolean)

    return {
      result: items.join(', '),
      itemsCount: items.length,
    }
  }

  /**
   * Форматирует уточнения по дому
   * @param { string } apartment - квартира
   * @param { string } entrance - подъезд
   * @param { string } floor - этаж
   * @param { string } intercom - домофон
   * @returns {string}
   */
  formatBuildingDetails({ apartment, entrance, floor, intercom }) {
    const items = [
      apartment
        ? `${this.gaApp.i18n.t('location.addressPrefix.apartment')} ${apartment}`
        : null,
      intercom
        ? `${this.gaApp.i18n.t('location.addressPrefix.intercom')} ${intercom}`
        : null,
      entrance
        ? `${this.gaApp.i18n.t('location.addressPrefix.entrance')} ${entrance}`
        : null,
      floor
        ? `${this.gaApp.i18n.t('location.addressPrefix.floor')} ${floor}`
        : null,
    ]

    return items.filter(Boolean).join(', ')
  }

  /**
   * Отдает полное название города, полученное с бэка
   * @param { object } location - адрес в формате location
   */
  formatAddressFull(location) {
    return location?.address?.addressNameFull || ''
  }

  /**
   * Собирает строку из поселения, города и региона
   * Используется в:
   * - ЛК модалка редактирования адреса на мобилках
   * - ЛК список адресов нижняя строка
   * @param { object } location - адрес в формате LocationEntity
   * @returns {string}
   */
  formatCityRegion(location) {
    const { region, regionTypeFull, city, settlement } = location?.address || {}

    const regionWithType = this.composeRegionWithType(region, regionTypeFull)

    const items = [settlement, city, regionWithType]
    const uniqueItems = [...new Set(items.filter(Boolean))]

    return uniqueItems.join(', ')
  }

  /**
   * Собирает массив из 2х срок: поселение/город и регион для отображения в столбик
   * Используется в:
   * - ЛК модалка редактирования адреса на десктопе
   * @param { object } location - адрес в формате LocationEntity
   * @returns { Array }
   */
  formatCityRegionCascade(location) {
    const { region, regionTypeFull, city, settlement } = location?.address || {}

    const regionWithType = this.composeRegionWithType(region, regionTypeFull)

    const items = [settlement, city, regionWithType]
    const uniqueItems = [...new Set(items.filter(Boolean))]
    const [noLastItems, lastItem] = [
      uniqueItems.slice(0, -1),
      uniqueItems.at(-1),
    ]

    return [noLastItems.join(', '), lastItem]
  }

  /*
   * Параметр query для запроса подсказок улицы (плейд)
   * */
  formatSuggestQuery(location = {}, street) {
    const { address } = location

    const {
      region: regionWithoutType,
      regionTypeFull,
      city,
      settlement,
      settlementWithType: settlementWithShortType,
    } = address || {}

    const regionWithType = this.composeRegionWithType(
      regionWithoutType,
      regionTypeFull,
    )

    const queryItems = [
      regionWithType,
      city,
      settlementWithShortType || settlement,
    ]

    const uniqueQueryItems = [...new Set(queryItems)]

    return [...uniqueQueryItems, street].filter(Boolean).join(', ')
  }

  /*
   * Параметры фильтрации подсказок для запроса улицы (плейд)
   * если герион и город одинаковые, то передаем только город. касается и query и отдельного параметра
   * */
  formatSuggestFilterParams(location = {}) {
    const {
      address,
      city: cityOrSettlement,
      region: regionWithShortType, // тут может быть localityRegion с полным типом если мы на ручках маги, у нас не Питер и не Москва и dadataJson пуст
    } = location

    const { region: regionWithoutType } = address || {}

    // должен быть регион без типа, либо регион с кратким типом
    // если регион с полным типом, то бэк ничего не найдет
    const region = regionWithoutType || regionWithShortType

    const items = [region, cityOrSettlement]
    const uniqueItems = [...new Set(items.filter(Boolean))]

    return {
      cityName: uniqueItems.pop(),
      regionName: uniqueItems.pop(),
    }
  }

  /**
   * Префикс города из локалей
   */
  get cityPrefix() {
    return this.gaApp.i18n.t('location.addressPrefix.city')
  }

  /**
   * Добавляем subtitle неразрывный пробел с префиксом города
   *
   * @param { string } subtitle - подзаголовок к городу из поискового саджеста
   */
  formatSuggestSubtitle(subtitle) {
    const regex = new RegExp(`${this.cityPrefix}\\s`, 'g')
    return subtitle.replace(regex, `${this.cityPrefix}&nbsp;`)
  }
}
