import { FORMAT } from '@ga/shared-dates'

export const GENDER = {
  male: {
    text: 'male',
    value: 1,
  },

  female: {
    text: 'female',
    value: 2,
  },

  default: {
    text: '',
    value: 0,
  },
}

export const DATE_FORMAT = {
  ru: 'DD / MM / YYYY',
  en: 'DD / MM / YYYY',
  ar: 'DD / MM / YYYY',
  international: 'YYYY-MM-DD',
  default: FORMAT.UTC,
}

export const BIRTHDAY_RANGE = {
  min: new Date(1900, 0, 1),
  max: new Date(),
}

export const VALIDATION = {
  textFieldPattern: /^[а-яёА-ЯЁa-zA-Zء-ي-\s]*$/,
  textFieldMaxLength: 30,
  birthdayPattern: /^\d{2}\s\/\s\d{2}\s\/\s\d{4}$/,
  emailFieldMaxLength: 255,
}

export const REQUIRED_FIELD_LIST = [
  'firstName',
  'lastName',
  'birthday',
  'email',
  'personalDataSigned',
]

export const FIELDS_MAP = {
  birthDate: 'birthday',
}
