import { TARGET } from '../../constants/common'

/**
 * Общий сервис для работы с данными продукта и доставки.
 * Является приватным абстрактный классом для сервисов main и preview.
 *
 * @class CommonService
 */
export class CommonService {
  constructor(gaApp, view) {
    this.gaApp = gaApp
    this.view = view ?? TARGET.MAIN
  }

  /**
   * Возвращает выбранный продукт для текущего представления.
   *
   * @return {Object} - Объект выбранного продукта.
   */
  get productSelected() {
    return this.gaApp.stores.pdp[this.view].productSelected
  }

  /**
   * Возвращает данные о продукте для текущего представления.
   *
   * @return {Object} - Объект с данными продукта.
   */
  get productData() {
    return this.gaApp.stores.pdp[this.view].productData
  }

  /**
   * Подписывается на данные продукта.
   *
   * @param {Object} data - Объект с данными для подписки.
   * @return {Promise<Object>} - Promise с объектом данных подписки.
   */
  subscribe(data = {}) {
    return this.gaApp.repositories.pdp.main.subscribe(data)
  }

  /**
   * Получает данные о доставке для указанного продукта.
   *
   * @param {Object} param - Объект с параметрами.
   * @param {string|null} param.itemId - Идентификатор продукта, по умолчанию null.
   * @return {Promise<Object>} - Promise с данными о доставке.
   */
  getDeliveryData({ itemId = null } = {}) {
    const params = { view: this.view, itemId }

    return this.gaApp.services.pdp.api.getDelivery(params)
  }

  /**
   * Получает данные о продукте.
   *
   * @param {Object} param - Объект с параметрами.
   * @param {string|null} param.itemId - Идентификатор продукта, по умолчанию null.
   * @return {Promise<Object>} - Promise с данными о продукте.
   */
  getProductData({ itemId = null } = {}) {
    return this.gaApp.services.pdp.api.getProduct({ view: this.view, itemId })
  }

  getProductDataBase({ itemId = null } = {}) {
    return this.gaApp.services.pdp.api.getProductBase({
      view: this.view,
      itemId,
    })
  }

  getProductDataAdditional({ itemId = null } = {}) {
    return this.gaApp.services.pdp.api.getProductAdditional({
      view: this.view,
      itemId,
    })
  }

  /**
   * Перезагружает данные о продукте.
   *
   * @return {Promise<Object>} - Promise с обновленными данными о продукте.
   */
  reloadProductData() {
    this.getProductDataAdditional()
  }

  /**
   * Перезагружает данные о доставке.
   *
   * @return {Promise<Object>} - Promise с обновленными данными о доставке.
   */
  reloadDeliveryData() {
    return this.getDeliveryData()
  }
}
