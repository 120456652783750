// import isEmpty from 'lodash/isEmpty'

import { Api } from '../api'

export default (context, inject) => {
  const { $axios, $gaApp } = context

  const urlPrefix = 'front/api'
  const api = new Api({
    client: $axios,
    urlPrefix,
  })

  // api.subscribe('start', ({ config }) => {
  //   if ($gaApp.isServer) return

  //   // const url = config.url.replace(urlPrefix, '')
  //   const transaction =
  //     $gaApp.services.app.apm.getCurrentTransaction() ||
  //     config.apmTransaction ||
  //     $gaApp.services.app.apm.startTransaction(
  //       `${config.method.toUpperCase()} ${config.url}`,
  //       'http-request',
  //       { managed: true }
  //     )

  //   const span = transaction.startSpan(
  //     `${config.method.toUpperCase()} ${config.url}`,
  //     'external.http',
  //     { blocking: true }
  //   )

  //   const data = config.data || config.params || {}

  //   if (!isEmpty(data)) {
  //     // span.addLabels(isPlainObject(data) ? data : { data })
  //     span.addLabels({ dataRequest: JSON.stringify(data) })
  //   }

  //   config.apmTransaction = transaction
  //   config.apmSpan = span
  // })

  // api.subscribe('end', ({ data }) => {
  //   if ($gaApp.isServer) return
  //   const config = data.config
  //   const dataResponse = JSON.stringify(data?.data?.data || {})

  //   // config.apmSpan.addLabels(
  //   //   isPlainObject(dataResponse) ? dataResponse : { data: dataResponse }
  //   // )

  //   config.apmSpan.addLabels({ dataResponse })
  //   config.apmSpan.end()
  //   config.apmTransaction.end()
  // })

  api.subscribe('error', ({ error }) => {
    console.error(error)
    if ($gaApp.isServer) return

    const config = error.config

    if (!config || !error.response?.status || !error.response?.data) {
      const dataError = JSON.stringify(error)
      const apiError = new Error(`ошибка api ${error.message}`)
      apiError.dataError = dataError

      // config.apmSpan.addLabels({ dataError })
      $gaApp.services.app.apm.captureError(apiError)

      return
    }

    const { data, ...apiError } = api.error(error, config.data)
    apiError.name = config.method + ' - ' + config.url
    apiError.message = `${apiError.statusCode} api ${config.url}`

    // config.apmSpan.addLabels(isPlainObject(data) ? data : { data })
    // config.apmSpan.addLabels({ dataResponse: JSON.stringify(data) })

    /**
     * Если заголовок X-Auth-Marker = false, значит его заблокирол WAF
     * необходимо залогировать ошибку в кибану
     */
    if (error.response.headers['x-auth-marker'] === 'false') {
      const errorWAF = new Error(`Запрос заблокирован`)
      errorWAF.errorData = apiError.dataError

      this.gaApp.services.app.apm.captureError(errorWAF)

      return
    }

    $gaApp.services.app.apm.captureError(apiError)

    // config.apmSpan.end()
    // config.apmTransaction.end()
  })

  inject('api', api)
}
