import isEmpty from 'lodash/isEmpty'

import { LOYALTY_QR_MODAL_STATE } from '../../constants/cards'

export const getters = (gaApp) => ({
  isLoading() {
    return this.state === LOYALTY_QR_MODAL_STATE.LOADING
  },

  isError() {
    return this.state === LOYALTY_QR_MODAL_STATE.ERROR
  },

  isUpdating() {
    return this.state === LOYALTY_QR_MODAL_STATE.UPDATING
  },

  dataFormatted() {
    const {
      qrBackground,
      qrBackgroundColor,
      infoUrl,
      cardNumber,
      qrCode,
      bonus,
      expirePoint,
    } = this.data ?? {}

    return {
      modalBackgroundImage: qrBackground,
      modalBackgroundColor:
        qrBackgroundColor || gaApp.stores.customer.cards.loyalty.cardColor,
      infoUrl,
      content: {
        cardNumber,
        qrCode,
        bonus,
        expireBonus: expirePoint,
        progress: this.progress,
        levels: this.levels,
      },
    }
  },

  levels() {
    const levels = this.data?.modalInfoClient?.levels

    if (!levels?.length) return

    return levels.map((level) => {
      const {
        header,
        levelName,
        description,
        background,
        leftToLevel,
        isCurrent,
      } = level

      let labelText = ''

      if (isCurrent) {
        labelText = gaApp.i18n.t(
          'customer.giftcards.loyaltyQrModal.level.current',
        )
      } else if (!isEmpty(leftToLevel)) {
        labelText = gaApp.i18n.t('customer.giftcards.loyaltyQrModal.level.next')
      }

      return {
        title: `${header?.left} ${header?.right}`,
        name: levelName,
        backgroundImage: background,
        description,
        labelText,
        labelValue: leftToLevel,
      }
    })
  },

  progress() {
    const { modalInfoSpecial, modalInfoClient } = this.data ?? {}

    // Если пришел modalInfoSpecial
    if (modalInfoSpecial) {
      // Возвращаем объект с данными
      return {
        ...modalInfoSpecial,
        labelText: modalInfoSpecial.additionalInfo,
      }
    }

    if (!modalInfoClient?.progress?.length) return

    // Ищем индекс текущего уровня прогресса
    const currentLevelIndex = modalInfoClient.progress.findIndex(
      (item) => item.isCurrent,
    )

    // Если текущий уровень прогресса - полследний
    if (currentLevelIndex === modalInfoClient.progress.length - 1) {
      const lastLevel = modalInfoClient.progress.at(-1)

      // Возвращаем объект с данными только этого уровня
      return {
        header: lastLevel.header,
        description: gaApp.i18n.t(
          'customer.giftcards.loyaltyQrModal.progressInfo.description',
        ),
        labelText: gaApp.i18n.t(
          'customer.giftcards.loyaltyQrModal.progressInfo.labelText',
        ),
      }
    }

    // Возвращаем отформатированный массив длиной n-1 от текущего,
    // если текущий уровень не последний и нет modalInfoSpecial
    return modalInfoClient.progress.reduce((acc, item, index) => {
      // добавляем все элементы кроме того, что идет ПОСЛЕ текущего
      if (currentLevelIndex + 1 !== index) {
        acc.push({
          ...item,
          labelText: !isEmpty(item.leftToLevel)
            ? gaApp.i18n.t(
                'customer.giftcards.loyaltyQrModal.progressTimeline.labelText',
              )
            : '',
          // Если это текущий уровень,
          nextLevelTitle: item.isCurrent
            ? // Добавляем заголовок следующего за ним уровня
              modalInfoClient.progress[index + 1]?.header?.left
            : '',
          labelValue: item.leftToLevel,
        })
      }

      return acc
    }, [])
  },
})
