import {
  FILTER_ABORT_KEY,
  FILTER_KEY,
  PROMOTION_QUERY_KEY,
  URL_PAGE_KEY,
} from '../constants/keys'
import { OFFERS_PAGE_SIZE, PAGE_TYPE } from '../constants/pages'
import { PAGINATION_DIRECTION } from '../constants/pagination'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async fetchCities() {
    try {
      const { cities, sorted: citiesSorted } =
        await this.gaApp.services.location.retailStores.fetchCities()

      this.gaApp.services.promo.location.setCities({ cities, citiesSorted })
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('promo.errors.fetchCities'),
      )
    }
  }

  async fetchCityStores() {
    const { data } = await this.gaApp.repositories.promo.main.getStores()
    this.gaApp.services.promo.stores.setStores(data.shops)
  }

  async fetchPromotions(page, direction = PAGINATION_DIRECTION.NEXT) {
    const params = {
      [PROMOTION_QUERY_KEY.SHOPS]: this.gaApp.stores.promo.listing.shopsKey,
      [PROMOTION_QUERY_KEY.LIMIT]: this.gaApp.stores.promo.page.getPageSize,
      [PROMOTION_QUERY_KEY.PAGE]: page,
    }

    try {
      this.gaApp.services.promo.pagination.setPagination({
        direction,
        value: {
          pending: true,
          error: false,
        },
      })

      const pageType = this.gaApp.stores.promo.page.pageType
      const cacheKey =
        pageType === PAGE_TYPE.LISTING_PROMO ? 'promo' : 'clientDays'

      const repositoryFn =
        pageType === PAGE_TYPE.LISTING_PROMO ? 'promotions' : 'clientDays'

      const cacheData = this.gaApp.services.cache.main.getServerData(cacheKey)
      let response =
        cacheData ||
        (await this.gaApp.repositories.promo.main[repositoryFn](params))

      // Если страницы с данными нет - грузим первую страницу
      if (response.data.count < page) {
        response = await this.gaApp.repositories.promo.main[repositoryFn]({
          ...params,
          [PROMOTION_QUERY_KEY.PAGE]: 1,
        })
      }

      const { data } = response

      this.gaApp.services.promo.listing.setPromotionsCount(data.count)

      this.gaApp.services.promo.listing.setPage({
        number: data.page,
        promotions: data.promotions,
      })

      this.gaApp.services.promo.listing.setMeta({
        metaTitle: data.metaTitle,
        metaDescription: data.metaDescription,
        metaKeywords: data.metaKeywords,
      })
    } catch (error) {
      this.gaApp.services.promo.pagination.setPagination({
        direction,
        value: { error: true },
      })
    } finally {
      this.gaApp.services.promo.pagination.setPagination({
        direction,
        value: { pending: false },
      })
    }
  }

  async fetchOffersByPage({ page, limit = OFFERS_PAGE_SIZE }) {
    const filters = this.gaApp.stores.filters.main.filtersSelected
    const quickFilters = this.gaApp.stores.filters.main.filtersQuickSelected

    const { id: cityId } =
      this.gaApp.services.location.main.getDeliveryAddress()
    const selectedCityId =
      filters.find((el) => el.key === FILTER_KEY.CITY_ID)?.value || cityId

    const cacheData = this.gaApp.services.cache.main.getServerData('offers')
    const { data } =
      cacheData ||
      (await this.gaApp.repositories.promo.main.offers({
        cityId: selectedCityId,
        pageNumber: page,
        limit,
        filters,
        quickFilters,
      }))

    this.gaApp.services.promo.offers.setOffersHash({
      filters,
      quickFilters,
    })

    this.gaApp.services.promo.offers.setOffersCount(data.count)

    this.gaApp.services.promo.offers.setPage({
      number: data.pageNumber,
      offers: data.offers,
    })

    return {
      data,
      filters,
      quickFilters,
      selectedCityId,
    }
  }

  // получение листинга акций
  async fetchOffers({
    page,
    limit = OFFERS_PAGE_SIZE,
    direction = PAGINATION_DIRECTION.NEXT,
  }) {
    try {
      this.gaApp.services.promo.pagination.setOffersPagination({
        direction,
        value: {
          pending: true,
          error: false,
        },
      })

      await this.fetchOffersByPage({ page, limit })
    } catch (error) {
      this.gaApp.services.promo.pagination.setOffersPagination({
        direction,
        value: { error: true },
      })
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('promo.errors.page'),
      )
    } finally {
      this.gaApp.services.promo.pagination.setOffersPagination({
        direction,
        value: { pending: false },
      })
    }
  }

  // получение листинга акций при первоначальной загрузке
  async initialListing({ page, limit = OFFERS_PAGE_SIZE }) {
    this.gaApp.services.promo.offers.setLoading(true)

    await this.gaApp.services.promo.offersFilters.getFilteredQueryFilters()

    let response = await this.fetchOffersByPage({ page, limit })

    // Если страницы с данными нет - грузим первую страницу
    if (response.data.count < page) {
      response = await this.gaApp.repositories.promo.main.offers({
        cityId: response.selectedCityId,
        pageNumber: 1,
        limit,
        filters: response.filters,
        quickFilters: response.quickFilters,
      })
    }

    const { data, selectedCityId } = response

    const formatFilters =
      await this.gaApp.services.filters.cities.updateFilterCitySubtitle(
        selectedCityId,
        data.filters,
      )
    this.gaApp.stores.filters.main.setFilters(formatFilters)
    this.gaApp.stores.filters.main.setFiltersQuick(data.quickFilters)

    this.gaApp.services.promo.offersFilters.updateFiltersUrlPostTask(
      this.gaApp.route.query[URL_PAGE_KEY] || data.pageNumber,
    )

    this.gaApp.services.filters.cities.setFilterCitiesDefault()

    this.gaApp.services.promo.offers.setLoading(false)
  }

  // получение фильтров для листинга
  fetchOffersFilters(params) {
    this.gaApp.api.requestAbort.abortRequest(FILTER_ABORT_KEY)

    return this.gaApp.repositories.promo.main.offersFilters(params, {
      abortKey: FILTER_ABORT_KEY,
    })
  }

  async fetchPromoInfo(promotionId) {
    const cacheData = this.gaApp.services.cache.main.getServerData('promoInfo')

    const { data } =
      cacheData ||
      (await this.gaApp.repositories.promo.main.promoInfo(promotionId))

    this.gaApp.stores.promo.main.info = data
    this.gaApp.services.promo.main.setMeta({
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription,
      inListing: data.inListing,
    })
  }

  // получение детальной страницы
  async fetchOfferInfo(offerUrl) {
    const cacheData = this.gaApp.services.cache.main.getServerData('offerInfo')

    const { data } =
      cacheData ||
      (await this.gaApp.repositories.promo.main.offerInfo(offerUrl))

    this.gaApp.stores.promo.offer.data = data
    this.gaApp.stores.promo.offer.data.url = offerUrl
  }

  // получение брендов исключений
  async fetchBrands(offerId) {
    const cacheData = this.gaApp.services.cache.main.getServerData('brands')

    const { data } =
      cacheData || (await this.gaApp.repositories.promo.main.brands(offerId))

    this.gaApp.stores.promo.search.id = data.id
    this.gaApp.stores.promo.search.brands = data.brands
    this.gaApp.stores.promo.search.sections = data.sections
  }
}
