import isEqual from 'lodash/isEqual'
import objectHash from 'object-hash'

import { URL_PAGE_KEY } from '../constants/keys'

export class OffersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async loadListing() {
    await this.gaApp.services.promo.api.initialListing({
      page: Number(this.gaApp.route.query[URL_PAGE_KEY]) || 1,
    })
  }

  async loadNewPage() {
    this.gaApp.services.promo.offers.setLoading(true)

    this.clearUrl()
    await this.gaApp.services.promo.offers.clearPages()
    await this.gaApp.services.promo.offers.loadPromotions(1)

    this.gaApp.services.promo.offers.setLoading(false)
  }

  setOffersCount(count) {
    this.gaApp.stores.promo.offers.offersCount = count
  }

  setPage(page) {
    this.gaApp.stores.promo.offers.pages.push(page)
    this.gaApp.stores.promo.offers.pages.sort((a, b) => a.number - b.number)
  }

  setLoading(isLoading) {
    this.gaApp.stores.promo.offers.isLoading = isLoading
  }

  setShouldReloadPage(shouldReloadPage) {
    this.gaApp.stores.promo.offers.shouldReloadPage = shouldReloadPage
  }

  addViewedId(id) {
    this.gaApp.stores.promo.offers.viewedIds.push(id)
  }

  isViewedSlide(id) {
    return this.gaApp.stores.promo.offers.viewedIds.find(
      (viewedId) => id === viewedId,
    )
  }

  // очистка просмотренных карточек в листинге при применении фильтров
  clearViewedIds() {
    this.gaApp.stores.promo.offers.viewedIds = []
  }

  clearPages() {
    this.gaApp.stores.promo.offers.pages = []
  }

  clearUrl() {
    const currentQuery = this.gaApp.route.query

    if (Object.keys(currentQuery).length) {
      this.gaApp.router.replace({ query: {} })
    }
  }

  async changeUrlPage(page) {
    const query = this.gaApp.route.query
    const queryPage = +query[URL_PAGE_KEY] || 1

    if (queryPage === page) {
      return
    }

    await this.gaApp.router.replace({
      query: {
        ...query,
        [URL_PAGE_KEY]: page > 1 ? page : undefined,
      },
    })
  }

  areHashesEqual() {
    const { filtersHash } = this.gaApp.stores.filters.main
    const { offersHash } = this.gaApp.stores.promo.offers

    return isEqual(filtersHash, offersHash)
  }

  resetListingAndFetchOffers() {
    if (this.areHashesEqual()) {
      return false
    }

    this.resetOffers()
    this.updateFilters()
    this.fetchOffers()
  }

  resetOffers() {
    requestAnimationFrame(() => {
      window.scrollTo({ top: 0 })
    })

    this.gaApp.services.promo.offers.clearPages()
  }

  updateFilters() {
    this.gaApp.services.filters.main.pageNumber.updateFilter(1)
    // TODO: временно прокидываем только номер сраницы
    this.gaApp.services.filters.main.updateFiltersURL({
      selected: false,
      quickSelected: false,
    })
  }

  async fetchOffers(page = 1) {
    await this.gaApp.services.promo.api.fetchOffers({ page })
  }

  setOffersHash(value) {
    this.gaApp.stores.promo.offers.offersHash = objectHash(value, {
      unorderedArrays: true,
    })
  }
}
