import { SCREEN_TYPE, STEPS } from '../../constants'

export const state = () => ({
  sets: {
    items: [],

    rules: {
      maxSets: 0,
      minValue: 0,
      maxValue: 0,
      minCount: 0,
      maxCount: 0,
    },
  },

  config: {
    cards: [],
    cities: [],
    city: '',
    isLoading: false,
  },

  steps: {
    [SCREEN_TYPE.MOBILE]: [
      STEPS.SETS,
      STEPS.DELIVERY,
      STEPS.COUNTERPARTY,
      STEPS.CONTACTS,
      STEPS.CHECKOUT,
    ],
    [SCREEN_TYPE.DESKTOP]: [STEPS.SETS, STEPS.DELIVERY],
  },

  isCityModalOpen: false,
  isAddressModalOpen: false,
  isStoreModalOpen: false,

  counterparty: {
    form: {
      inn: '',
      agreed: false,
      approved: false,
    },
    info: {
      name: '',
      kpp: '',
      ognip: '',
      address: '',
    },
    rules: {
      maxInnLength: 0,
      minInnLength: 0,
    },
    isLoading: false,
    isInfoVisible: false,
  },

  contacts: {
    form: {
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      recipientName: '',
      recipientPhone: '',

      document: null,
    },

    rules: {
      maxContactNameLength: 0,
      maxRecipientNameLength: 0,
    },

    document: '',

    isSameContactPerson: false,
  },

  deliveryInfo: {
    options: [],
    type: '',

    addressForm: {
      street: '', // улица и дом
      apt: '',
      intercom: '',
      entrance: '',
      floor: '',
    },

    store: {},

    addressString: '',

    comment: '',

    isInfoBlockVisible: false,

    price: 0,
  },

  requestIdCache: {},
})
