import { endpoints as commonEndpoints } from '../../../repositories/common/endpoints'

export const endpoints = {
  ...commonEndpoints,
  getProduct: () => ({
    url: 'catalog/product-card',
  }),
  getProductBase: () => ({
    url: 'catalog/product-card/base',
  }),
  // ручка для ME. Используется с ФТ pdpUnavailableCrosscountry
  getProductBaseV2: () => ({
    url: 'catalog/product-card/base/v2',
  }),
  getProductAdditional: () => ({
    url: 'catalog/product-card/additional',
  }),
  getProductAdditionalV2: () => ({
    url: 'catalog/product-card/additional/v2',
  }),
  getRecommendations: () => ({
    url: 'catalog/placements',
  }),
  getBenefits: () => ({
    url: 'catalog/benefits',
  }),
  getBenefitsV2: () => ({
    url: 'catalog/benefits/v2',
  }),
  getSlots: () => ({
    url: 'catalog/product/slots',
  }),
  getSlotsV2: () => ({
    url: 'catalog/product/slots-v2',
  }),
  getSlotsV3: () => ({
    url: 'catalog/product/slots-v3',
  }),
  getCityStores: () => ({
    url: 'retail-stores/city/stores',
  }),
  getStoresStock: () => ({
    url: 'retail-stores/city/stores/stock',
  }),
}
