import { FILTER_ABORT_KEY, FILTER_KEY } from '../constants/keys'

export class OffersFiltersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Выполняет запрос на получение фильтров акций на основе выбранных фильтров.
   * Устанавливает в стор фильтры, быстрые фильтры, и количество выбранных фильтров.
   *
   * @return {Promise<Object|undefined>}
   */
  async requestFilters() {
    try {
      this.gaApp.stores.filters.main.setFiltersPending(true)

      const filters = this.gaApp.stores.filters.main.filtersSelected
      const quickFilters = this.gaApp.stores.filters.main.filtersQuickSelected

      const { id: cityId } =
        this.gaApp.services.location.main.getDeliveryAddress()
      const selectedCityId =
        filters.find((el) => el.key === FILTER_KEY.CITY_ID)?.value || cityId

      const { data } = await this.gaApp.services.promo.api.fetchOffersFilters({
        cityId: selectedCityId,
        filters,
        quickFilters,
      })

      this.gaApp.stores.filters.main.setFiltersQuick(data.quickFilters)
      this.gaApp.stores.filters.main.setAnalytics(data.analytics)

      const filtersSelected =
        this.gaApp.services.filters.selectedFilters.getSelected(data.filters)
      this.gaApp.stores.filters.main.setFiltersSelected(filtersSelected)

      this.gaApp.stores.filters.main.setFiltersHash({
        filters: filtersSelected,
        quickFilters,
      })

      this.gaApp.stores.filters.main.setCountSelectedFilters(
        data.countSelectedFilters,
      )
      this.gaApp.stores.filters.main.setCountProductsFiltered(
        data.filteredCount,
      )

      const formatFilters =
        await this.gaApp.services.filters.cities.updateFilterCitySubtitle(
          selectedCityId,
          data.filters,
        )
      this.gaApp.stores.filters.main.setFilters(formatFilters)

      this.gaApp.stores.filters.main.setFiltersPending(false)

      return data
    } catch (error) {
      if (error?.message === FILTER_ABORT_KEY) {
        return
      }

      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('promo.error.filters'),
      )

      this.gaApp.stores.filters.main.setFiltersPending(false)
    }
  }

  /**
   * Метод нужен, чтобы получить актуальный список выбранных фильтров на основе тех,
   * которые пришли с бека и которые находятся в query params
   * @returns {Promise<Array<object>>}
   */
  async getFilteredQueryFilters() {
    // Сбрасываем стейт, чтобы получить фильтры, относящиеся только к текущей локации
    this.gaApp.services.filters.main.resetState()

    // Устанавливаем ссылку на функцию запроса фильтров, т.к. после ресета
    // эта ссылка теряется
    this.gaApp.services.filters.main.setRequestFiltersFn(
      this.gaApp.services.promo.offersFilters.requestFilters.bind(
        this.gaApp.services.promo.offersFilters,
      ),
    )

    const { filters, quickFilters } =
      await this.gaApp.services.promo.offersFilters.requestFilters()

    const query = this.gaApp.route.query

    // Получаем список только тех фильтров из query, которые есть и в query, и в фильтрах,
    // пришедших с бека
    const filtersFromQuery =
      this.gaApp.services.filters.queryParse.getFiltersFromQuery(filters, query)
    const quickFiltersFromQuery =
      this.gaApp.services.filters.queryParse.getFiltersFromQuery(
        quickFilters,
        query,
      )

    // Устанавливаем актуальные выбранные фильтры
    this.gaApp.stores.filters.main.setFiltersSelected(filtersFromQuery)
    this.gaApp.stores.filters.main.setFiltersQuickSelected(
      quickFiltersFromQuery,
    )

    this.gaApp.stores.filters.main.setFiltersHash({
      filters: filtersFromQuery,
      quickFilters: quickFiltersFromQuery,
    })

    // Устанавливаем актуальное кол-во выбранных фильтров
    this.gaApp.stores.filters.main.setCountSelectedFilters(
      this.gaApp.stores.filters.main.getFiltersMainSelected.length,
    )

    return filtersFromQuery
  }

  getFiltersQuickData(data) {
    // Так как быстрые фильтры возвращаются в виде структуры обычных фильтров (массив объектов), то в нём только один объект с быстрыми фильтрами. Обращаемся к этому объекту и достаем только values (массив объектов со значениями быстрых фильтров), так как ключ, айди и прочее нам не нужно.
    const filters = data?.[0]?.values || []

    const selectedFilters = filters.filter((el) => el.isSelected)
    const values = selectedFilters.map((el) => el.id)

    const options = filters.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      }
    })

    return {
      values,
      options,
    }
  }

  /**
   * Нужно для того, чтобы обновить query параметры, убрав оттуда
   * те фильтры, которых нет в текущей локации пользака
   *
   * @param {string} pageNumber - номер страницы
   */
  updateFiltersUrlPostTask(pageNumber) {
    this.gaApp.services.app.scheduler.postTasks([
      {
        task: () => {
          this.gaApp.services.filters.main.onPageNumberChange(
            pageNumber,
            true,
            // TODO: временно прокидываем только номер сраницы
            { selected: false, quickSelected: false },
          )
        },
      },
      // { TODO: доработать добавление фильтров в URL
      //   task: () => {
      //     this.gaApp.services.filters.main.updateFiltersURL()
      //   },
      // },
    ])
  }
}
