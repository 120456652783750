import { SUGGEST_ABORT_KEY, SUGGEST_TYPE } from '../constants/search'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Обогащает параметры для запроса подсказок
   * @param { object } params - параметры, которые дополняем в методе
   * @param { string } params.query - строка поиска
   * @param { object } settings - настройки обогащения
   * @param { boolean } settings.addFiltersToParams - добавление фильтрующих подсказки параметров - город и регион
   * @param { boolean } settings.addFiltersToQuery - добавление города и региона в query для более точного запроса
   */
  enrichSuggestParams(
    params,
    { addFiltersToParams = false, addFiltersToQuery = false } = {},
  ) {
    const result = { ...params }

    // locationTemp - временный адрес с формы редактирования
    // location - текущий адрес юзера
    const location =
      this.gaApp.stores.location.main.locationTemp ||
      this.gaApp.stores.location.main.location

    if (params.query && addFiltersToQuery) {
      // query c cityName и regionName
      result.query = this.gaApp.services.location.formatter.formatSuggestQuery(
        location,
        params.query,
      )
    }

    // cityName и regionName отдельными параметрами
    if (addFiltersToParams) {
      const { cityName, regionName } =
        this.gaApp.services.location.formatter.formatSuggestFilterParams(
          location,
        )

      result.cityName = cityName
      result.regionName = regionName
    }

    return result
  }

  /**
   * Запрос подсказок
   * @param params
   * @returns { array | undefined | Error }
   * - массив подсказок в случае успеха;
   * - undefined в случае отмены запроса
   * - ошибку, поэтому нужно оборачивать в try...catch
   */
  async suggest(params) {
    try {
      // отменяем предыдущий запрос
      this.gaApp.api.requestAbort.abortRequest(SUGGEST_ABORT_KEY)

      const { data } = await this.gaApp.repositories.location.main.geoSuggest(
        params,
        {
          abortKey: SUGGEST_ABORT_KEY,
        },
      )

      return data
    } catch (error) {
      // не показываем уведомление если это мы отменили запрос
      if (error?.message === SUGGEST_ABORT_KEY) {
        return
      }

      // todo: обновить текстовки для саджеста/геокода и разные типы запроса
      //  сейчас текст подходит только для саджеста по городам
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.search.error.default'),
      )
      throw error
    }
  }

  /**
   * Запрос подсказок по городам/поселениям
   * @param { string } query - строка поиска
   */
  suggestCities(query) {
    const params = {
      query,
      type: SUGGEST_TYPE.CITY,
    }

    return this.suggest(params)
  }

  /**
   * Запрос подсказок по улице и дому
   * @param { string } query - строка поиска
   */
  suggestStreets(query) {
    const params = this.enrichSuggestParams(
      {
        query,
        type: SUGGEST_TYPE.STREET,
      },
      {
        addFiltersToQuery: true,
        addFiltersToParams: true,
      },
    )

    return this.suggest(params)
  }

  /**
   * Запрос подсказок по местам, ориентирам
   * @param { string } query - строка поиска
   */
  suggestPlaces(query) {
    const params = this.enrichSuggestParams(
      {
        query,
      },
      {
        addFiltersToParams: true,
      },
    )

    return this.suggest(params)
  }

  /**
   * Запрос подсказок по районам
   * @param { string } query - строка поиска
   */
  suggestDistrict(query) {
    const params = this.enrichSuggestParams(
      {
        query,
        type: SUGGEST_TYPE.DISTRICT,
      },
      {
        addFiltersToQuery: true,
        addFiltersToParams: true,
      },
    )

    return this.suggest(params)
  }

  /**
   * Запрос адреса по подсказке
   * @returns { object | Error }
   * - адрес в случае успеха;
   * - ошибку, поэтому нужно оборачивать в try...catch
   */
  async geocode(params) {
    try {
      const { data } =
        await this.gaApp.repositories.location.main.geoGeocode(params)

      return data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.search.error.default'),
      )
      throw error
    }
  }

  /**
   * Запрос адреса по подсказке с городом
   * @param objectId - фиас адреса с подсказки
   * @param fullAddress - полный адрес с подсказки
   */
  geocodeCities(objectId, fullAddress) {
    const params = {
      objectId,
      type: SUGGEST_TYPE.CITY,
      query: fullAddress,
    }

    return this.geocode(params)
  }

  /*
   * Запрос адреса по подсказке с улицей и домом
   * @param objectId - фиас адреса с подсказки
   * @param fullAddress - полный адрес с подсказки
   * */
  geocodeStreets(objectId, fullAddress) {
    const params = this.enrichSuggestParams(
      {
        objectId,
        type: SUGGEST_TYPE.STREET,
        query: fullAddress,
      },
      {
        addFiltersToParams: true,
      },
    )

    return this.geocode(params)
  }

  /*
   * Запрос адреса по подсказке без типа
   * @param objectId - фиас адреса с подсказки
   * */
  geocodePlaces(objectId) {
    const params = this.enrichSuggestParams(
      {
        objectId,
      },
      {
        addFiltersToParams: true,
      },
    )

    return this.geocode(params)
  }

  /*
   * Запрос локации по координатам в плейд
   * */
  async reverseGeocode(
    coordinates,
    requestParams,
    { hasAlertError } = { hasAlertError: true },
  ) {
    try {
      const { data } =
        await this.gaApp.repositories.location.main.geoReverseGeocode(
          {
            useDefaultCity: true, // вернет дефолтный город, если нет ответа от дадаты по координатам
            ...coordinates,
          },
          requestParams,
        )

      return data
    } catch (error) {
      if (hasAlertError) {
        this.gaApp.services.notification.main.open(
          this.gaApp.i18n.t('location.geolocation.error.default'),
        )
      }

      throw error
    }
  }

  async getGeolocation(
    { longitude, latitude, abortKey },
    { hasAlertError } = { hasAlertError: true },
  ) {
    try {
      this.gaApp.services.location.geolocation.setPending(true)
      const data = await this.gaApp.services.location.api.reverseGeocode(
        {
          lon: longitude,
          lat: latitude,
        },
        { abortKey },
        { hasAlertError },
      )

      if (!data?.id) {
        throw new Error('Ошибка')
      }

      return data
    } catch (error) {
      // внутри reverseGeocode уже есть уведомление, если словили ошибку

      return null
    } finally {
      this.gaApp.services.location.geolocation.setPending(false)
    }
  }

  /**
   * Получение сохраненных и выбранного адреса
   */
  async getAddress() {
    try {
      const cacheData =
        this.gaApp.services.cache.main.getServerData('userAddress')

      const { data } =
        cacheData || (await this.gaApp.repositories.location.main.getAddress())

      const { addresses, selectedAddress } = data || {}

      if (addresses) {
        this.gaApp.services.location.main.setAddresses(addresses)
      }

      if (selectedAddress) {
        this.gaApp.services.location.main.setLocation(selectedAddress)
      }

      return data
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Смена адреса
   * если авторизован - бэк записывает адрес
   * если неавторизован - бэк обогащает адрес, а адрес хранится на клиенте
   */
  async setAddress(data) {
    const address = {
      id: data.saveId,
      addressId: data.addressId || data.exactId || data.id,
      dadataJson: data?.dadataJSON || null,
    }

    if (data.latitude && data.longitude) {
      address.addressLatitude = data.latitude
      address.addressLongitude = data.longitude
    }

    try {
      const { data } =
        await this.gaApp.repositories.location.main.setAddress(address)

      const { addresses, selectedAddress } = data || {}

      if (addresses) {
        this.gaApp.services.location.main.setAddresses(addresses)
      }

      if (selectedAddress) {
        this.gaApp.services.location.main.setLocation(selectedAddress)
        this.gaApp.services.location.cache.saveGuestSelectedAddress(
          selectedAddress,
        )
      }

      if (
        selectedAddress &&
        !this.gaApp.stores.app.common.toggle.addressBlockEnabled
      ) {
        this.gaApp.services.location.confirm.saveClientLocation(
          selectedAddress.id,
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Получение геолокации по ip
   * фича дадаты и РФ
   *
   * Что можно получить в ответе:
   * РФ - адрес по ip | дефолтный город
   * Остальные страны - дефолтный город
   */
  async getLocationByIp() {
    // нет смысла дергать, если не РФ
    if (!this.gaApp.isCountry.ru) {
      return
    }

    try {
      const { data } =
        await this.gaApp.repositories.location.main.getLocationByIp()

      const { location } = data || {}

      if (!location.city || !location.id) {
        const error = new Error('Ошибка получения локации по ip')
        error.data = JSON.stringify(data || {})

        this.gaApp.services.app.apm.captureError(error)
        this.gaApp.services.location.main.setLocationDefault()
        return
      }

      return location
    } catch (error) {
      this.gaApp.services.location.main.setLocationDefault()

      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.error.get'),
      )
    }
  }

  async getCitiesModalData() {
    const { data } =
      await this.gaApp.repositories.location.retailStores.getCitiesModalData()

    if (!data?.cities) {
      throw new Error('Список c городами пуст')
    }

    return data
  }
}
