import isEqual from 'lodash/isEqual'
import isFunction from 'lodash/isFunction'
import set from 'lodash/set'

import { isTruthyIncludingZero } from '@ga/utils'

import { FILTER_PAGE_NUMBER, TYPE, TYPES_RESET_IMMUNITY } from '../../constant'
import { isDefaultValue } from '../../utils'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Предоставляет методы для работы с локальным фильтром pageNumber
   */
  get pageNumber() {
    // Получение значения параметра из URL
    const getFromQuery = () => {
      const pageString = this.gaApp.route.query[FILTER_PAGE_NUMBER.key]
      const pageNumber = Number(pageString)

      return isNaN(pageNumber) || pageNumber < 1 ? 1 : pageNumber
    }

    // Обновление локального фильтра pageNumber новым значением
    const updateFilter = (value) => {
      this.updateFilterLocaly({ ...FILTER_PAGE_NUMBER, value })
    }

    // Обновление фильтра номера страницы в URL
    const updateFilterUrl = () => {
      const key = FILTER_PAGE_NUMBER.key
      const queryValue = Number(this.gaApp.route.query[key])
      const filterValue = this.gaApp.stores.filters.main.getPageNumberCurrent

      if (!isEqual(queryValue, filterValue)) {
        return this.gaApp.router.replace({
          query: {
            ...this.gaApp.route.query,
            [key]: filterValue,
          },
        })
      }
    }

    // Устанавливает локальный фильтр значением полученным из URL
    const setFromQuery = () => updateFilter(getFromQuery())

    return {
      updateFilterUrl,
      updateFilter,
      setFromQuery,
      getFromQuery,
    }
  }

  areHashesEqual() {
    const fn = this.gaApp.stores.filters.main.getAreHashesEqualFn

    return isFunction(fn) ? fn() : false
  }

  updateFilter({ categoryId, filter }) {
    this.updateFilterSelected(filter)

    const {
      id: cityId,
      cityDistrict,
      geoPolygons,
    } = this.gaApp.services.location.main.getDeliveryAddress()

    return this.gaApp.services.filters.api.requestFilters({
      categoryId,
      cityId,
      cityDistrict,
      geoPolygons,
    })
  }

  resetFilters({ categoryId }) {
    const {
      id: cityId,
      cityDistrict,
      geoPolygons,
    } = this.gaApp.services.location.main.getDeliveryAddress()

    if (this.gaApp.stores.filters.main.getFiltersPending) {
      return false
    }

    const filters = this.gaApp.stores.filters.main.filtersSelected.filter(
      (filter) => TYPES_RESET_IMMUNITY.includes(filter.type),
    )
    this.gaApp.stores.filters.main.setFiltersSelected(filters)

    return this.gaApp.services.filters.api.requestFilters({
      categoryId,
      cityId,
      cityDistrict,
      geoPolygons,
    })
  }

  resetFilterById({ categoryId, id }) {
    const {
      id: cityId,
      cityDistrict,
      geoPolygons,
    } = this.gaApp.services.location.main.getDeliveryAddress()

    this.gaApp.stores.filters.main.resetFilterSelectedById(id)

    return this.gaApp.services.filters.api.requestFilters({
      categoryId,
      cityId,
      cityDistrict,
      geoPolygons,
    })
  }

  /**
   * Метод нужен для установки/обновления параметров фильтров в URL
   *
   * @param {object} settings настройки формирования массива фильтров
   * @param {boolean} settings.selected включение выбранных фильтров
   * @param {boolean} settings.localy включение локальных фильтров
   * @param {boolean} settings.quickSelected включение быстрых фильтров
   * @returns {void} обновляет значения фильтров в URL
   */
  updateFiltersURL({
    selected = true,
    localy = true,
    quickSelected = true,
  } = {}) {
    const { filtersSelected, filtersLocaly, filtersQuickSelected } =
      this.gaApp.stores.filters.main

    const filters = []

    if (selected) {
      filters.push(...filtersSelected)
    }

    if (localy) {
      filters.push(...filtersLocaly)
    }

    if (quickSelected) {
      filters.push(...filtersQuickSelected)
    }

    const query = this.buildFiltersForQuery(filters)

    if (!isEqual(this.gaApp.route.query, query)) {
      return this.gaApp.router.replace({ query })
    }
  }

  /**
   * Метод нужен для обновления НЕлокальных выбранных фильтров
   */
  updateFilterSelected(filter) {
    if (filter.type === TYPE.QUICK) {
      return this.updateFilterSelectedQuick(filter)
    }
    return this.updateFilterSelectedMain(filter)
  }

  updateFilterSelectedMain(filter) {
    const filtersSelected = this.gaApp.stores.filters.main.filtersSelected

    // Сброс выбранных быстрых фильтров при выборе фильтров модалки, чтобы они не отправлялись в запросе
    this.gaApp.stores.filters.main.setFiltersQuickSelected([])

    const updatedSelectedFilters = this.getUpdatedSelectedFilters(
      filtersSelected,
      filter,
    )

    this.gaApp.stores.filters.main.setFiltersSelected(updatedSelectedFilters)
  }

  updateFilterSelectedQuick(filter) {
    const filtersSelected = this.gaApp.stores.filters.main.filtersQuickSelected

    const updatedSelectedFilters = this.getUpdatedSelectedFilters(
      filtersSelected,
      filter,
    )

    this.gaApp.stores.filters.main.setFiltersQuickSelected(
      updatedSelectedFilters,
    )
  }

  getUpdatedSelectedFilters(filtersSelected, filter) {
    const newFiltersSelected = filtersSelected.filter(
      (item) => item.id !== filter.id,
    )

    switch (filter.type) {
      case TYPE.CHECKLIST_MULTIPLE:
      case TYPE.CHECKLIST_TREE:
      case TYPE.QUICK:
        ;(() => {
          if (filter.values?.length) {
            newFiltersSelected.push(filter)
          }
        })()
        break

      case TYPE.CHECKBOX:
      case TYPE.CHECKLIST_SINGLE:
        ;(() => {
          if (
            !isDefaultValue(filter, this.gaApp.stores.filters.main.filters) &&
            isTruthyIncludingZero(filter.value)
          ) {
            newFiltersSelected.push(filter)
          }
        })()
        break

      case TYPE.SORTING:
        ;(() => {
          if (filter.valueId) {
            newFiltersSelected.push(filter)
          }
        })()
        break

      case TYPE.RANGE:
        ;(() => {
          const isSourceValues =
            filter.currentMaxValue.amount === filter.maxValue?.amount &&
            filter.currentMinValue.amount === filter.minValue?.amount

          if (!isSourceValues) {
            newFiltersSelected.push(filter)
          }
        })()
        break

      default:
        ;(() => {
          newFiltersSelected.push(filter)
        })()
    }

    return newFiltersSelected
  }

  /**
   * Метод нужен для обновления локальных фильтров
   */
  updateFilterLocaly(filter) {
    const filtersLocaly = this.gaApp.stores.filters.main.filtersLocaly

    const updatedLocalyFilters = this.getUpdateLocalyFilters(
      filtersLocaly,
      filter,
    )

    this.gaApp.stores.filters.main.setFiltersLocaly(updatedLocalyFilters)
  }

  getUpdateLocalyFilters(filtersLocaly, filter) {
    const newFiltersLocaly = filtersLocaly.filter(
      (item) => item.id !== filter.id,
    )

    if ([TYPE.LOCALY].includes(filter.type)) {
      if (filter.value > 1) {
        newFiltersLocaly.push(filter)
      }
    }

    return newFiltersLocaly
  }

  /**
   * Наполняет пустой объект полями с значениями фильтра
   * Возвращает обновленный объект значения фильтра
   *
   * @param {object} filter объект фильтра
   * @param {any} value обновленное значение фильтра
   * @returns {object} обновленный объект значения фильтра
   */
  getUpdatedFilterValue = (filter, value) => {
    const filterValue = {}

    switch (filter.type) {
      case TYPE.RANGE:
        set(filterValue, 'minValue', { ...filter.minValue })
        set(filterValue, 'maxValue', { ...filter.maxValue })
        set(filterValue, 'currentMinValue', { ...filter.currentMinValue })
        set(filterValue, 'currentMaxValue', { ...filter.currentMaxValue })
        set(filterValue, 'currentMinValue.amount', value[0])
        set(filterValue, 'currentMaxValue.amount', value[1])
        return filterValue

      case TYPE.CHECKLIST_SINGLE:
        set(filterValue, 'value', Array.isArray(value) ? value[0] : value)
        return filterValue

      case TYPE.CHECKLIST_MULTIPLE:
      case TYPE.CHECKLIST_TREE:
      case TYPE.QUICK:
        set(filterValue, 'values', value.filter(isTruthyIncludingZero))
        return filterValue

      case TYPE.SORTING:
        set(filterValue, 'valueId', value)
        return filterValue

      default:
        set(filterValue, 'value', value)
        return filterValue
    }
  }

  /**
   * Принимает фильтр, и приводит его значение к паре вида [key, value]
   * Нужно для того, что бы вставить приведенное значение в URL
   *
   * @param {Object} param0 фильтр из стора
   * @returns пару вида [key, value]
   */
  mapKeyValue(filter) {
    const { key, type } = filter

    switch (type) {
      case TYPE.RANGE:
        return (() => {
          const values = [
            filter.currentMinValue.amount,
            filter.currentMaxValue.amount,
          ]

          return [key, values.join('-')]
        })()

      case TYPE.CHECKLIST_MULTIPLE:
      case TYPE.CHECKLIST_TREE:
        return [key, filter.values.join(',')]

      case TYPE.CHECKBOX:
        return [key, '1']

      case TYPE.SORTING:
        return [key, filter.valueId]

      case TYPE.QUICK:
        return [key, filter.values.join(',')]

      default:
        return [key, filter.value]
    }
  }

  /**
   * Принимает объект аналитики фильтра, и нормализует значение
   *
   * @param {object} filterAnalytics объект аналитики фильтра
   * @returns пару вида [key, value]
   */
  getCortegeAnalytics(filterAnalytics) {
    const { formattedName, type, data } = filterAnalytics

    switch (type) {
      // Для этих типов фильтров склеиваем значения смовлом "|"
      case TYPE.CHECKLIST_SINGLE:
      case TYPE.CHECKLIST_MULTIPLE:
      case TYPE.CHECKLIST_TREE:
        return [formattedName, data.selected.join('|')]

      // Доступ к значению выбранной сортировки, есть только на фронте,
      // Поэтому если на фронте выбрана сортировка, то берем значение из БФФ
      case TYPE.SORTING:
        return [
          formattedName,
          this.gaApp.stores.filters.main.getSortingValueId
            ? data.selected
            : null,
        ]

      // Для остальных типов фильтров возвращаем значение из data.selected
      default:
        return [formattedName, data.selected]
    }
  }

  /**
   * Для страницы поиска при наличии meta в pageData.params убираем фильтр q,
   * чтобы не от ображать его в get-параметрах
   * TODO выпилить, когда бэк не будет присылать неактуальные фильтры на таких страницах
   *
   * @param filters
   */
  excludeNotActual(filters) {
    if (
      this.gaApp.services.plp.main.isPageTypeSearch() &&
      this.gaApp.stores.plp.main.pageData?.params?.meta
    ) {
      return filters.filter((item) => item.type !== TYPE.QUERY)
    }
    return filters
  }

  /**
   * Получения объекта с установленными фильтрами.
   * Нужно, для дальнейшней установки query-параметров фильтров в URL.
   *
   * @param {array} filters массив выбранных фильтров
   * @returns {object} Возвращает объект, где элементы имеют вид { [key]: value }
   *                   key - ключ фильтра; value - значение фильтра
   */
  buildFiltersForQuery(filters = []) {
    const actualFilters = this.excludeNotActual(filters)

    return Object.fromEntries(
      actualFilters
        .sort((a, b) => a.key.localeCompare(b.key))
        .map(this.mapKeyValue),
    )
  }

  /**
   * Обновляем локальный фильтр сначала в сторе, затем в URL,
   * если передан соответствующий флаг
   *
   * @param {number} value номер страницы
   * @param {boolean} updateUrl флаг обновления URL
   * @param {object} urlSettings настройки обновления URL
   */
  onPageNumberChange(value, updateUrl = false, urlSettings) {
    // Нужно привести значение к строке,
    // что бы небыло расхожения типов в URL и сторе
    this.pageNumber.updateFilter(value.toString())

    if (updateUrl) {
      this.updateFiltersURL(urlSettings)
    }
  }

  setRequestFiltersFn(fn) {
    this.gaApp.stores.filters.main.setRequestFiltersFn(fn)
  }

  setAreHashesEqualFn(fn) {
    this.gaApp.stores.filters.main.setAreHashesEqualFn(fn)
  }

  setGetItemListNameFn(fn) {
    this.gaApp.stores.filters.main.setGetItemListNameFn(fn)
  }

  setGetAnalyticDataFn(fn) {
    this.gaApp.stores.filters.main.setGetAnalyticDataFn(fn)
  }

  resetState() {
    this.gaApp.stores.filters.main.resetState()
  }

  getFormattedForOrdersFilterList() {
    return this.gaApp.stores.filters.main.formattedForOrdersFilterList
  }
}
