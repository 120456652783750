import { Bus, WindowAdapter } from '@waves/waves-browser-bus'

import { AVAILABLE_GAMES, EVENT_TO_DISPATCH, EVENT_TO_LISTEN } from '../consts'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.bus = null
  }

  get isAuth() {
    return (
      this.gaApp.stores.game.main.hasMobileToken ||
      this.gaApp.stores.user.main.isAuthorized
    )
  }

  /** запрос данных пользователя при наличии токена в урл на старте */
  async getUserData() {
    const isMobile = this.gaApp.features.get('gameMobileUserInfoFull')

    const { data } = isMobile
      ? await this.gaApp.services.user.api.fetchUserMobileInfoFullWithToken()
      : await this.gaApp.services.user.api.fetchUserInfoFullWithToken()

    this.gaApp.stores.game.main.user = data
  }

  /** удаление токена из урл */
  removeTokenFromUrl() {
    const query = { ...this.gaApp.route.query }
    delete query.token

    const searchParams = new URLSearchParams(query)

    let nextUrl = this.gaApp.route.path

    if (searchParams.toString()) {
      nextUrl += `?${searchParams.toString()}`
    }

    this.gaApp.services.app.router.replaceState(null, '', nextUrl)
  }

  /** генерируем тело сообщения для игры */
  getUserMessage() {
    const { smsSigned, emailSigned, pushSigned } = this.gaApp.isWebview
      ? this.gaApp.stores.game.main.user
      : this.gaApp.stores.user.main.data

    const message = {
      isWeb: !this.gaApp.isWebview,
      smsSigned: smsSigned ?? null,
      emailSigned: emailSigned ?? null,
      pushSigned: pushSigned ?? null,
      share: this.gaApp.route.query?.profile_id || null,
      timestamp: Date.now(),
    }

    if (this.isAuth) {
      const { gameToken, processingStatus } = this.gaApp.stores.game.main

      message.token = gameToken
      message.processingStatus = processingStatus
    }

    return message
  }

  /**
   * вызывается при старте страницы
   * если в режиме вебвью, проверяем наличие токена в урл,
   * сохраняем в куки, удаляем из урл, запрашиваем данные пользователя
   * если авторизован, идем за токеном игры
   */
  async init(name) {
    this.gaApp.stores.game.main.name = name

    if (this.gaApp.isWebview) {
      const token = this.gaApp.route.query?.token

      if (token) {
        this.gaApp.stores.game.main.hasMobileToken = true
        this.gaApp.cookies.set('access_token', token, {
          path: '/',
          sameSite: 'lax',
        })
        this.removeTokenFromUrl()
        await this.getUserData()
      }
    }

    if (this.isAuth) {
      await this.gaApp.services.game.api.getToken(
        this.gaApp.stores.game.main.name,
      )
    }
  }

  sendParams() {
    const query = { ...this.gaApp.route.query }

    this.bus.dispatchEvent(EVENT_TO_DISPATCH.GET_PARAMS, query)
    console.log('GA SEND - ' + EVENT_TO_DISPATCH.GET_PARAMS, query)
  }

  /** Отправка USER сообщения в iframe */
  sendUser() {
    const message = this.getUserMessage()
    this.bus.dispatchEvent(EVENT_TO_DISPATCH.USER, message)
    console.log('GA SEND - ' + EVENT_TO_DISPATCH.USER, message)
  }

  sendAddedToFavoritesProduct(product) {
    this.bus.dispatchEvent(EVENT_TO_DISPATCH.ADD_TO_FAVORITES_SUCCESS, product)
    console.log('GA SEND - ' + EVENT_TO_DISPATCH.USER, product)
  }

  sendCartItemsSku(itemsSku) {
    this.bus.dispatchEvent(
      EVENT_TO_DISPATCH.GET_CART_ITEMS_SKU_SUCCESS,
      itemsSku,
    )
    console.log(
      'GA SEND - ' + EVENT_TO_DISPATCH.GET_CART_ITEMS_SKU_SUCCESS,
      itemsSku,
    )
  }

  /** Вызывается после авторизации, получаем токен игры и отправляем данные */
  async afterAuth() {
    await this.gaApp.services.game.api.getToken(
      this.gaApp.stores.game.main.name,
    )
    this.sendUser()
  }

  /**
   * Запрашиваем данные о товаре по sku
   * и добавляем товар в корзину
   */
  async addToCart({ sku }) {
    const product = await this.gaApp.services.game.api.fetchProductBase({
      itemId: sku,
    })

    if (product) {
      product.data.id = product.data.itemId

      this.gaApp.services.cart.api.addItem(product.data)
    }
  }

  /**
   * Добавляем товар в избранные
   */
  addToFavorites({ sku }) {
    return this.gaApp.services.favorites.api.addProducts([sku])
  }

  handleGetParams() {
    this.sendParams()
  }

  /**
   * обработчик GET_USER события игры,
   * при запросе отправляем данные пользака в игру
   */
  handleGetUser() {
    this.sendUser()
  }

  /**
   * обработчик AUTH события игры, открываем окно авторизации
   * после авторизации отправляем данные пользака в игру
   */
  handleAuth() {
    // если пользователь авторизован, ничего не делаем
    if (this.isAuth) {
      return
    }

    // если webview, то переходим по урл, который перехватит мп
    if (this.gaApp.isWebview) {
      window.location = `${this.gaApp.route.path}/auth`
      return
    }

    this.gaApp.services.auth.main.openModal({
      callbacks: {
        register: () => {
          // отправить данные в игру
          this.afterAuth()
        },
        login: () => {
          // отправить данные в игру
          this.afterAuth()
        },
      },
    })
  }

  /**
   * обработчик SHARE события игры
   */
  handleShare({ url }) {
    if (!url) {
      return
    }

    // если webview, меняем урл, который она должна отловить
    if (this.gaApp.isWebview) {
      window.location = `${this.gaApp.route.path}/share?url=${url}`
      return
    }

    // иначе открываем урл в соседней вкладке
    window.open(url, '_blank')
  }

  /**
   * обработчик OPEN события игры
   */
  handleOpen({ url }) {
    if (!url) {
      return
    }

    // если webview, меняем урл, который она должна отловить
    if (this.gaApp.isWebview) {
      window.location = `${this.gaApp.route.path}/open?url=${url}`
      return
    }

    // иначе открываем урл в соседней вкладке
    window.open(url, '_blank')
  }

  /**
   * обработчик RELOAD события игры
   * перезагружает текущую страницу
   * пишем в кибану, чтоб посмотреть статистику
   */
  handleReload() {
    const error = new Error(
      `GAME/${this.gaApp.stores.game.main.name} - перезагрузка игры `,
    )
    error.data = JSON.stringify({})

    this.gaApp.services.app.apm.captureError(error)

    // дожидаемся отправки лога в кибану
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  /**
   * Обработчик `ADD_TO_FAVORITES` события игры
   * добавляет товар в избранные по переданному sku
   */
  async handleAddToFavorites({ sku }) {
    if (!sku) {
      return
    }

    try {
      await this.addToFavorites({ sku })

      this.sendAddedToFavoritesProduct({ sku })
    } catch (e) {
      const error = new Error(
        `GAME/${this.gaApp.stores.game.main.name} - не удалось добавить товар в избранные `,
      )
      error.data = JSON.stringify({})

      this.gaApp.services.app.apm.captureError(error)
    }
  }

  /**
   * Обработчик `GET_CART_ITEMS_SKU` события игры
   * собирает массив sku товаров из корзины
   * и возвращает обратным событием `GET_CART_ITEMS_SKU_SUCCESS`
   */
  handleGetCartItemsSku() {
    const itemsSku = this.gaApp.stores.cart.main.itemsId || []

    this.sendCartItemsSku(itemsSku)
  }

  /**
   * Обработчик `ADD_TO_CART` события игры
   * добавляет товар в корзину по переданному sku
   */
  handleAddToCart({ sku }) {
    if (!sku) {
      return
    }

    if (this.gaApp.isWebview) {
      window.open(
        `${window.origin}/cart?products[0][id]=${sku}&products[0][qty]=1&stream_id=8989006`,
        '_self',
      )
      return
    }

    this.addToCart({ sku })
  }

  /** Подписываемся на ивенты */
  attachEvents() {
    this.bus.on(EVENT_TO_LISTEN.GET_PARAMS, (data) => {
      console.log('GA GET - ' + EVENT_TO_LISTEN.GET_PARAMS, data)
      this.handleGetParams()
    })

    this.bus.on(EVENT_TO_LISTEN.GET_USER, (data) => {
      console.log('GA GET - ' + EVENT_TO_LISTEN.GET_USER, data)
      this.handleGetUser()
    })

    this.bus.on(EVENT_TO_LISTEN.AUTH, (data) => {
      console.log('GA GET - ' + EVENT_TO_LISTEN.AUTH, data)
      this.handleAuth()
    })

    this.bus.on(EVENT_TO_LISTEN.SHARE, (data) => {
      console.log('GA GET - ' + EVENT_TO_LISTEN.SHARE, data)
      this.handleShare(data)
    })

    this.bus.on(EVENT_TO_LISTEN.OPEN, (data) => {
      console.log('GA GET - ' + EVENT_TO_LISTEN.OPEN, data)
      this.handleOpen(data)
    })

    this.bus.on(EVENT_TO_LISTEN.RELOAD, (data) => {
      console.log('GA GET - ' + EVENT_TO_LISTEN.RELOAD, data)
      this.handleReload(data)
    })

    this.bus.on(EVENT_TO_LISTEN.ADD_TO_CART, (data) => {
      console.log('GA GET - ' + EVENT_TO_LISTEN.ADD_TO_CART, data)
      this.handleAddToCart(data)
    })

    this.bus.on(EVENT_TO_LISTEN.ADD_TO_FAVORITES, (data) => {
      console.log('GA GET - ' + EVENT_TO_LISTEN.ADD_TO_FAVORITES, data)
      this.handleAddToFavorites(data)
    })

    this.bus.on(EVENT_TO_LISTEN.GET_CART_ITEMS_SKU, (data) => {
      console.log('GA GET - ' + EVENT_TO_LISTEN.GET_CART_ITEMS_SKU, data)
      this.handleGetCartItemsSku(data)
    })
  }

  /** Создаем iframe и шину */
  initBus() {
    const { country } = this.gaApp.i18n.locale
    const url = AVAILABLE_GAMES[country][this.gaApp.stores.game.main.name]

    const iframe = document.createElement('iframe')

    // credentialless - встраивание через iframe стороннего контента, который будет обработан в изолированном от основного сайта окружении
    // с пустыми Cookie и отдельными хранилищами, такими как LocalStorage и CacheStorage
    iframe.setAttribute('credentialless', true)

    // sandbox - песочница для iframe,  для ограничений на загружаемый фрейм, чтобы повысить безопасность текущей страницы
    // allow-scripts - разрешаем запускт скриптов
    // allow-same-origin - без него не работает postmessage т.к. нарушается общение между iframe и parent
    // Разрешает загружать содержание фрейма, воспринимая его из того же источника, что и родительский документ. Может использоваться для безопасного открытия контента, блокируя при этом всплывающие окна.
    // allow-downloads - разрешаем скачивание файлов из iframe
    iframe.setAttribute(
      'sandbox',
      'allow-scripts allow-same-origin allow-forms allow-downloads',
    )

    // Настройки Content Security Policy
    iframe.setAttribute(
      'csp',
      'sandbox allow-scripts allow-same-origin allow-forms',
    )

    // allow - перечисляем список апи с их запретом
    iframe.setAttribute(
      'allow',
      "accelerometer 'none'; ambient-light-sensor 'none'; attribution-reporting 'none'; autoplay 'none'; battery 'none'; bluetooth 'none'; browsing-topics 'none'; camera 'none'; compute-pressure 'none'; display-capture 'none'; domain-agent 'none'; document-domain 'none'; encrypted-media 'none'; execution-while-not-rendered 'none'; execution-while-out-of-viewport ''; gamepad 'none'; geolocation 'none'; gyroscope 'none'; hid 'none'; identity-credentials-get 'none'; idle-detection 'none'; local-fonts 'none'; magnetometer 'none'; microphone 'none'; midi 'none'; otp-credentials 'none'; payment 'none'; picture-in-picture 'none'; publickey-credentials-create 'none'; publickey-credentials-get 'none'; screen-wake-lock 'none'; serial 'none'; speaker-selection 'none'; usb 'none'; window-management 'none'; xr-spatial-tracking 'none'; storage-access 'none'; clipboard-write",
    )

    WindowAdapter.createSimpleWindowAdapter(iframe, { origins: url }).then(
      (adapter) => {
        this.bus = new Bus(adapter)
        this.attachEvents()
      },
    )

    iframe.src = url

    return {
      iframe,
    }
  }
}
