import * as components from './components'
import { locales } from './locales'
import { services } from './services'
import { stores } from './stores'

export {
  useSuperModal,
  useSuperModalHooks,
  useModalStore,
  useQueueModals,
} from './composable'

export {
  AUTH_MODAL_REASON_TO_OPEN,
  AUTH_MODAL_TYPE_NAMES,
  AUTH_MODAL_TYPES,
  MODAL_NAME,
} from './constants'

export const modal = {
  meta: {
    name: 'modal',
    locales,
  },
  components,
  layers: {
    services,
    stores,
  },
}
