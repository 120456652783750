// TODO: убрать getFullName, getAvatar, getFormattedPhone
// и добавить аналогичные им компьютеды в useUser

import { getId } from '@ga/utils'

import { DEFAULT_AVATAR, IS_ADULT_COOKIE_KEY } from '../constants'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.setSubscriptions()
  }

  get data() {
    return this.gaApp.stores.user.main.data
  }

  get isAuthorized() {
    return this.gaApp.stores.user.main.isAuthorized
  }

  setUserLanguage(code) {
    this.gaApp.stores.user.main.data.language = code
  }

  setLocaleAsUserLanguage() {
    this.gaApp.stores.user.main.data.language = this.gaApp.i18n.locale.code
  }

  /*
   * Обновляет язык (коммуникаций) пользователя на переданный в аргументах, если пользоваель авторизован
   */
  async updateUserLanguage(code) {
    const isAuthorized = this.gaApp.services.user.main.isAuthorized

    if (
      !isAuthorized ||
      !this.gaApp.services.app.langSwitcher.hasAvailableLanguages
    )
      return

    const response = await this.gaApp.services.user.api.updateUserLanguage({
      language: code,
    })

    return response
  }

  /*
   * Обновляет язык (коммуникаций) пользователя в соответствии с текущей локалью,
   * если переданный язык отличается от сохраненного языка в сторе user.
   *
   * Если запрос отработал успешно, записывает новое значение в стор user
   */
  async updateAndSetUserLanguage() {
    const nextCode = this.gaApp.services.app.langSwitcher.getApiLanguageCode(
      this.gaApp.i18n.locale.code,
    )

    if (this.gaApp.stores.user.main.data.language === nextCode) return

    const response = await this.updateUserLanguage(nextCode)

    if (response) {
      this.gaApp.features.get('updateUserLanguagePlaid')
        ? this.setLocaleAsUserLanguage()
        : this.setUserLanguage(response.language)
    }
  }

  resetUser() {
    this.gaApp.stores.user.main.data = {}
    this.gaApp.stores.user.main.isAuthorized = false
  }

  resetUserAndRefresh() {
    // если пользователь ранее не был авторизован, выходим
    if (!this.gaApp.stores.user.main.isAuthorized) return

    // иначе - сбрасываем стор юзера
    this.resetUser()

    if (this.gaApp.services.app.router.isAuthRequired(this.gaApp.route.path)) {
      // Если это роут требующий авторизации, то редиректим юзера с рефрешем  на главную
      window.location.assign('/')
    } else {
      // иначе - просто рефреш
      window.location.assign(this.gaApp.route.fullPath)
    }
  }

  getIsAdult(birthday) {
    const dateNow = this.gaApp.libs.dateWrapper()
    const birthdayInternal = this.gaApp.libs.dateWrapper(birthday)

    const userAge = dateNow.diff(birthdayInternal, 'years')

    const adultAge = this.gaApp.stores.app.common.adultAge

    const isUserAdult = userAge >= adultAge

    return this.gaApp.cookies.get(IS_ADULT_COOKIE_KEY) || isUserAdult
  }

  checkIfAdult(reason) {
    const userIsAdult = this.gaApp.stores.user.main.isAdult
    const userIsAdultWasAsked = this.gaApp.stores.user.main.isAdultWasAsked

    if (userIsAdult || userIsAdultWasAsked) {
      return
    }

    this.gaApp.services.modal.main.openSuperModalAdult({
      reason,
    })
  }

  setUser({ data }) {
    const user = {
      ...this.gaApp.stores.user.main.data,
      ...data,
    }

    this.gaApp.stores.user.main.data = { ...user }

    if (data) {
      this.gaApp.stores.user.main.isAdult =
        this.gaApp.services.user.main.getIsAdult(user.birthDate)

      this.gaApp.stores.user.main.isAuthorized = Boolean(user)

      this.gaApp.services.app.apm.setUserContext({ id: user.id })

      this.gaApp.services.app.apm.setCustomContext({ user })
    } else {
      this.gaApp.services.app.apm.setCustomContext({ user: null })
    }
  }

  getFullName() {
    const firstName = this.data.firstName
    const lastName = this.data.lastName

    if (!firstName && !lastName) {
      return null
    }

    return firstName + ' ' + lastName
  }

  getFormattedPhone() {
    const phone = this.data.phone

    if (!phone) {
      return null
    }

    return this.gaApp.services.app.phone.getFormattedPhone(phone)
  }

  getAvatar() {
    return this.data.avatar?.url ? this.data.avatar : DEFAULT_AVATAR
  }

  setUserViewGUID() {
    this.gaApp.stores.user.main.viewGUID = getId()
  }

  // Подписываемся на ивенты
  setSubscriptions() {
    // При смене языка
    if (!this.gaApp.features.get('updateUserLanguagePlaid')) {
      this.gaApp.eventBus.subscribe(
        'module/app/lang-switcher/locale-changed',
        (code) => {
          const nextCode =
            this.gaApp.services.app.langSwitcher.getApiLanguageCode(code)

          // Обновляем язык пользователю
          this.gaApp.services.user.main.updateUserLanguage(nextCode)
        },
      )
    }

    // При логине
    this.gaApp.eventBus.subscribe('module/auth/login', async () => {
      // Обновляем язык пользователю
      await this.gaApp.services.user.main.updateAndSetUserLanguage()

      this.removeIsAdultCookie()
    })

    // При авторизации на ините приложения
    if (this.gaApp.features.get('updateUserLanguagePlaid')) {
      this.gaApp.eventBus.subscribe('module/auth/init', async () => {
        await this.gaApp.services.user.main.updateAndSetUserLanguage()
      })
    }

    // При logout
    this.gaApp.eventBus.subscribe('module/auth/logout', () =>
      this.removeIsAdultCookie(),
    )
  }

  removeIsAdultCookie() {
    this.gaApp.cookies.remove(IS_ADULT_COOKIE_KEY)
  }

  getProofAge() {
    // Берем данные из cookies, либо считаем что возраст не подтвержден
    this.gaApp.stores.user.main.isAdult =
      this.gaApp.cookies.get(IS_ADULT_COOKIE_KEY) ?? false
  }

  setProofAge() {
    const expiresDate = new Date()
    expiresDate.setFullYear(expiresDate.getFullYear() + 1)

    // Записываем подтверждение возраста в cookies
    this.gaApp.cookies.set(IS_ADULT_COOKIE_KEY, true, {
      expires: expiresDate,
      path: '/',
      sameSite: 'lax',
    })
  }

  /**
   * Проверяет, существует ли переданный номер телефона (как юзер) в странах СНГ
   * Вызывает запрос проверки только для случая, когда
   * - текущая страна - страна Middle East
   * - переданный номер телефона принадлежит СНГ
   *
   * @param {string} phoneCode - страна кода номера телефона (ru/by/kz/qa/ae)
   * @param {string} phone - номер телефона
   * @returns {boolean}
   */
  async checkOtherCountriesPhoneExistence({ phoneCode, phone }) {
    const isCISPhone = this.gaApp.services.app.phone.isCISPhone(phoneCode)
    const isMiddleEastCountry = this.gaApp.isMiddleEast

    if (!isCISPhone || !isMiddleEastCountry) {
      return false
    } else {
      const exist = await this.gaApp.services.user.api.checkPhoneNumber({
        phone,
        country: phoneCode,
      })

      return exist ?? false
    }
  }
}
