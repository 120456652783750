import { AUTH_MODAL_REASON_TO_OPEN } from '~/modules/modal'

export class ModalAuthTypesService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Формирует редирект URL для внешних сервисов авторизации
   *
   * @returns {string}
   */
  authRedirectBackUrl() {
    return this.gaApp.route.fullPath
  }

  /**
   * Определяет, что модалка открыта через попытку зайти на защищённую авторизацией страницу
   * и при этом в local storage на этапе this.gaApp.services.auth.main.onAuthFailed()
   * уже сохранился редирект URL
   *
   * @returns {boolean}
   */
  hasRedirectUrlFromRelogin() {
    const reason =
      this.gaApp.services.auth.localStorage.getLocalStorageAuthModalOpenReason()

    return (
      reason === AUTH_MODAL_REASON_TO_OPEN.RELOGIN &&
      this.gaApp.services.auth.localStorage.getLocalStorageAuthRedirectUrl()
    )
  }

  /**
   * Устанавливаем редирект URL в local storage для последующего возврата на соответствующую страницу,
   * если он не был установлен ранее по причине relogin'а
   */
  saveRedirectUrlToStorage() {
    if (!this.hasRedirectUrlFromRelogin()) {
      this.gaApp.services.auth.localStorage.setLocalStorageAuthRedirectUrl(
        this.authRedirectBackUrl(),
      )
    }
  }

  /** Запрашивает список способов авторизации, сохраняет в стор */
  async fetchAuthTypes() {
    const data = await this.gaApp.services.auth.api.getAuthTypes()

    this.gaApp.stores.auth.main.externalAuthTypes = data.externalAuthTypes || []
  }

  /**
   * Открывает страницу авторизации внешнего сервиса
   *
   * @param {string} externalAuthServiceUrl
   */
  goToExternalAuthService(externalAuthServiceUrl) {
    window.location.assign(externalAuthServiceUrl)
  }

  /**
   * Отображает сообщение об ошибке
   *
   * @param {string} serviceName
   */
  showErrorMessage(serviceName) {
    const message =
      this.gaApp.i18n.t(`auth.error.externalAuthFailed.${serviceName}`) ??
      this.gaApp.i18n.t(`auth.error.externalAuthFailed.default`)

    this.gaApp.services.notification.main.open(message)
  }

  /**
   * Редиректит на страницу авторизации через внешние сервисы (яндекс, вк и тд),
   * сохраняет данные, необходимые при возврате в local storage
   */
  async redirectToExternalAuthService(serviceName) {
    const externalAuthServiceUrl =
      await this.gaApp.services.auth.externalAuthBuilder.buildRedirectUrlByService(
        serviceName,
      )

    if (externalAuthServiceUrl) {
      this.saveRedirectUrlToStorage()
      this.goToExternalAuthService(externalAuthServiceUrl)
    } else {
      this.showErrorMessage(serviceName)
    }
  }
}
