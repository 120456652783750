import { useContext } from '@nuxtjs/composition-api'

import { computed, ref, toRefs } from 'vue'

import { GaCountryFlag } from '../../../country-flag'
import { GaSelect } from '../../../select'

import { SIZE } from './scripts/constants'
import { useSwipeModal } from './scripts/use/swipe-modal'

// @vue/component
export default {
  name: 'ga-input-phone-select-code',
  components: {
    GaSelect,
    GaCountryFlag,
  },
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    callingCode: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: SIZE.S,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    options: {
      type: Array,
      default: () => [],
    },
    withModal: {
      type: Boolean,
      default: false,
    },
    modalZIndex: {
      type: Number,
      default: null,
    },
    rtl: {
      type: Boolean,
      default: false,
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { $gaApp } = useContext()

    const { options } = toRefs(props)

    const isNewFlagComponent = $gaApp.features.get('newFlagComponent')

    const selectRef = ref()
    const dropdownPlacement = computed(() =>
      props.rtl ? 'bottom-end' : 'bottom-start',
    )

    const onModalFinishClose = () => {
      selectRef.value?.close()
    }

    const { swipeModalHeight } = useSwipeModal(options)

    return {
      selectRef,
      swipeModalHeight,
      dropdownPlacement,
      onModalFinishClose,

      isNewFlagComponent,
    }
  },
}
