export const endpoints = {
  redirect: () => ({
    url: 'catalog/redirect',
  }),

  common: () => ({
    url: 'common/data',
  }),

  cookieAgreementText: () => ({
    url: 'cookie/agreement/text',
  }),

  appInfo: () => ({
    url: `_static/api/app-info`,
  }),
}
