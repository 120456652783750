let crypto

/**
 * Динамическое подключение Node-модуля crypto
 */
function getCrypto() {
  if (!crypto) {
    crypto = require('crypto')
  }
  return crypto
}

/**
 * Генерирует Code Verifier для PKCE (Proof Key for Code Exchange)
 * ссылка на спецификацию: https://datatracker.ietf.org/doc/html/rfc7636#section-4.1
 *
 * @returns {string}
 */
const generateCodeVerifier = () => {
  return getCrypto()
    .randomBytes(32)
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

/**
 * Генерирует Code Challenge для PKCE (Proof Key for Code Exchange)
 * ссылка на спецификацию: https://datatracker.ietf.org/doc/html/rfc7636#section-4.2
 *
 * @returns {string}
 */
const generateCodeChallenge = (codeVerifier) => {
  const hash = getCrypto()
    .createHash('sha256')
    .update(codeVerifier)
    .digest('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
  return hash
}

/**
 * Генерирует пару PKCE (Proof Key for Code Exchange)
 * ссылка на спецификацию: https://datatracker.ietf.org/doc/html/rfc7636#section-4
 *
 * @returns {codeChallenge: string, codeVerifier: string}
 */
export const generatePkcePair = () => {
  const codeVerifier = generateCodeVerifier()
  const codeChallenge = generateCodeChallenge(codeVerifier)

  return {
    codeVerifier,
    codeChallenge,
  }
}
