import qs from 'qs'

import { generatePkcePair } from '@ga/utils'

import {
  CLIENT_ID,
  EXTERNAL_AUTH_SERVICE,
  GRANT_TYPE,
  REQUEST_FIELD_NAME,
} from '../constants'

import { BaseBuilder } from './base-builder.service'

/** билдер для внешнего сервиса авторизации VK */
export class VkBuilder {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.baseBuilder = new BaseBuilder(gaApp, EXTERNAL_AUTH_SERVICE.VK)
  }

  /**
   * Генерирует пару PKCE (подробнее в описании поля code_challenge документации
   * https://id.vk.com/about/business/go/docs/ru/vkid/latest/vk-id/connection/start-integration/auth-without-sdk/auth-without-sdk-web)
   * и сохраняет code_verivier в local storage, чтобы в последствии его можно было отправить на бэк
   *
   * @returns {string}
   */
  buildChellengeCodeAndSaveVerifier() {
    const pkceChallengePair = generatePkcePair()

    this.gaApp.services.auth.localStorage.setLocalStorageAuthVerifier(
      pkceChallengePair.codeVerifier,
    )

    return pkceChallengePair.codeChallenge
  }

  /**
   * Формирует параметры для URL перехода на внешний сервис
   */
  buildRedirectParams() {
    const params = this.baseBuilder.getParams()

    // обогощаем необходимыми динамическими параметрами:
    params.code_challenge = this.buildChellengeCodeAndSaveVerifier()
    params.redirect_uri = this.baseBuilder.buildRedirectUri()

    return params
  }

  /**
   * Формирует URL перехода на внешние сервисы авторизации
   */
  buildRedirectUrl() {
    return this.baseBuilder.buildUrl(this.buildRedirectParams())
  }

  /**
   * Получает client_id параметры для отправки на бэк для авторизации через VK
   */
  getVkIdClient() {
    return this.baseBuilder.getParams().client_id
  }

  /**
   * Получает сгенерированный ранее code_verifier из local storage и удаляет запись
   */
  extractCodeVerifier() {
    const verifier =
      this.gaApp.services.auth.localStorage.getLocalStorageAuthVerifier()
    this.gaApp.services.auth.localStorage.removeLocalStorageAuthVerifier()

    return verifier
  }

  /**
   * Формирует параметры для отправки на бэк для авторизации
   *
   * @param {Object} queryParams
   */
  buildRequestPayload(queryParams) {
    return qs.stringify({
      [REQUEST_FIELD_NAME.CLIENT_ID]: CLIENT_ID,
      [REQUEST_FIELD_NAME.CHALLENGE_CODE]: queryParams.code,
      [REQUEST_FIELD_NAME.GRANT_TYPE]: GRANT_TYPE[EXTERNAL_AUTH_SERVICE.VK],
      [REQUEST_FIELD_NAME.CODE_VERIFIER]: this.extractCodeVerifier(),
      [REQUEST_FIELD_NAME.VK_CLIENT_ID]: this.getVkIdClient(),
      [REQUEST_FIELD_NAME.REDIRECT_URI]: this.baseBuilder.buildRedirectUri(),
      [REQUEST_FIELD_NAME.VK_DEVICE_ID]: queryParams.device_id,
    })
  }
}
