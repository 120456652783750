import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'

import {
  DATE_FORMAT,
  FIELDS_MAP,
  GENDER,
  LOYALTY_CARD_STATE,
} from '../constants'

export class EditService {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.editStore = this.gaApp.stores.customer.edit
  }

  setFormData(data) {
    this.editStore.form.firstName = data.firstName || ''
    this.editStore.form.lastName = data.lastName || ''
    this.editStore.form.middleName = data.middleName || ''
    this.editStore.form.gender = data.gender || GENDER.default.value
    this.editStore.form.birthday = data.birthDate
      ? this.gaApp.libs
          .dateWrapper(data.birthDate)
          .format(DATE_FORMAT[this.gaApp.i18n.locale.code])
      : ''
    this.editStore.form.email = data.email || ''
    this.editStore.form.phone = data.phone || ''
    this.editStore.form.personalDataSigned = data.personalDataSigned ?? false

    this.editStore.form.subscriptionsSigned = data.subscriptionsSigned ?? false

    this.editStore.form.city = !isEmpty(data.city)
      ? data.city
      : { name: '', cityFiasId: '' }
  }

  setAdditionalData(data) {
    this.editStore.isEmailConfirmed = !!data.emailVerified

    this.editStore.wasPhoneSaved = !!data.phone
    this.editStore.isBirthdayEditBlocked = data.actions
      ? !data.actions.enableEditBirthDate
      : false

    this.editStore.personalDataSigned = data.personalDataSigned ?? false

    this.editStore.subscriptionsSigned = data.subscriptionsSigned ?? false

    this.editStore.fields = data.fields ?? []

    this.editStore.profileFilled = data.profileFilled ?? false
  }

  setCity({ location }) {
    if (!location) {
      return
    }

    this.editStore.form.city = {
      name: location.city,
      cityFiasId: location.id,
    }
  }

  async saveForm() {
    // Сохраняем текущее состояние заполненности профиля
    const profileFilledBeforeUpdate = this.editStore.profileFilled

    // Сохраняем текущий статус карты лояльности
    const hasLoyaltyCard =
      this.gaApp.stores.user.main.data.loyaltyCardState ===
      LOYALTY_CARD_STATE.HAS_CARD

    // Сохраняем информацию профиля
    await this.gaApp.services.customer.api.saveProfileInfo(
      this.editStore.normalizedForm,
    )

    // Определяем, нужно ли перенаправлять пользователя на страницу карт лояльности
    const hasToRedirect =
      !profileFilledBeforeUpdate &&
      !hasLoyaltyCard &&
      this.editStore.profileFilled &&
      this.gaApp.features.get('showLoyaltyCardScreens')

    // Проверка нужен ли редирект
    if (hasToRedirect) {
      this.gaApp.services.customer.cards.redirectToCardsPage({
        animate: true, // Включаем анимацию появления карты лояльности при перенаправлении
      })
    }
  }

  /**
   * Обработка 400 ошибки при апдейте личных данных пользователя
   * @param {Object} errorData данные полученной ошибки
   */
  handleValidationError(errorData) {
    const invalidParameters = errorData?.invalidParameters

    // формируем объект ошибок в зависимости от наличия параметров ошибки
    const fieldsErrors = invalidParameters
      ? this.getFormErrors(invalidParameters)
      : null

    // если сформированный объект ошибок не пустой
    if (!isEmpty(fieldsErrors)) {
      // сохраняем в стор этот объект ошибок (непосредственно в поля ошибки сетятся в скрипте компонента)
      this.gaApp.stores.customer.edit.formErrors = fieldsErrors
    } else {
      // иначе показываем дефолтную нотификацию об ошибке сохранения данных
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.edit.notification.error'),
      )
    }
  }

  /**
   * Возвращает объект с ошибками полей
   * @param {Object} errorParameters данные полученной ошибки
   * @returns {object} объект, где ключи - имена полей формы,
   * а значения - строка localizedMessage из полученных с бэка invalidParameters ошибки
   */
  getFormErrors(errorParameters) {
    return Object.entries(errorParameters).reduce((acc, [key, value]) => {
      // TODO: Заменить имя поля др в форме ЛК и убрать мапинг
      const fieldName = FIELDS_MAP[key] ?? key
      if (
        // проверяем наличие ключа объекта параметров ошибки среди полей формы в сторе
        has(this.gaApp.stores.customer.edit.form, fieldName) &&
        // и наличие сообщения в значении этого ключа
        value.localizedMessage
      ) {
        // заполняем объект ошибок свойствами "имя поля: ошибка"
        acc[fieldName] = value.localizedMessage
      }

      return acc
    }, {})
  }

  resetFormErrors() {
    this.gaApp.stores.customer.edit.formErrors = null
  }

  setWasSaved(value) {
    this.gaApp.stores.customer.edit.wasSaved = value
  }

  setBirthdayEditBlocked(value) {
    this.gaApp.stores.customer.edit.isBirthdayEditBlocked = value
  }

  openEmailConfirmModal() {
    this.gaApp.stores.customer.edit.isEmailConfirmModalOpened = true
  }

  openSupportEditModal() {
    this.gaApp.stores.customer.edit.isSupportEditModalOpened = true
  }
}
