import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { GaIconPrimaryGlobe } from '@ga/icons'

import { GaAction } from '@ga/ui-components/action'
import { GaCountryFlag } from '@ga/ui-components/country-flag'
import { GaIcon } from '@ga/ui-components/icon'
import { GaImage } from '@ga/ui-components/image'

import { GaHeaderNavMinimizedArrow } from '../nav-minimized-arrow'

// @vue/component
export default {
  name: 'ga-header-nav-minimized-lang-switcher',
  components: {
    GaHeaderNavMinimizedArrow,
    GaAction,
    GaIcon,
    GaIconPrimaryGlobe,
    GaCountryFlag,
    GaImage,
  },
  setup() {
    const { $gaApp } = useContext()

    const isNewFlagComponent = $gaApp.features.get('newFlagComponent')

    const currentStore = computed(
      () => $gaApp.services.app.langSwitcher.currentStore,
    )

    const currentLanguage = computed(
      () => $gaApp.services.app.langSwitcher.currentLanguage,
    )

    const onLangClick = () => $gaApp.services.app.langSwitcher.openModal()
    const onCountryClick = () =>
      $gaApp.services.app.langSwitcher.openCountryModal()

    const storeSwitcherFeatureOn = $gaApp.features.get('storeSwitcher')

    return {
      storeSwitcherFeatureOn,

      currentStore,
      currentLanguage,

      onLangClick,
      onCountryClick,

      isNewFlagComponent,
    }
  },
}
