import debounce from 'lodash/debounce'

import { SEARCH_DEBOUNCE_DELAY } from '../constants/search'

export class StreetsService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.getSearchDataDebounced = debounce(
      this.getSearchDataDebounced,
      SEARCH_DEBOUNCE_DELAY,
    )
  }

  updateQuery(value) {
    this.gaApp.stores.location.search.streets.query = value

    if (value.trim()) {
      this.pendingStart()
      this.getSearchDataDebounced(value)
    } else {
      this.resetState()
    }
  }

  updateQueryTemp(value) {
    this.gaApp.stores.location.search.streets.queryTemp = value
  }

  pendingStart() {
    this.gaApp.stores.location.search.streets.pending = true
  }

  pendingStop() {
    this.gaApp.stores.location.search.streets.pending = false
  }

  async getSearchDataDebounced(value) {
    try {
      const streets =
        await this.gaApp.services.location.api.suggestStreets(value)

      // если отменили запрос - в data будет undefined
      if (streets) {
        this.gaApp.stores.location.search.streets.items = streets
      }
    } catch (error) {
      console.error(error)
    }

    this.pendingStop()
  }

  resetState() {
    this.gaApp.stores.location.search.streets.items = []
    this.gaApp.stores.location.search.streets.query = ''
    this.gaApp.stores.location.search.streets.pending = false
  }

  /**
   * Возвращает подсказку с полными данными, включая локацию
   * @param { object } option - подсказка
   * @param { string } option.value - фиас города
   * @param { string } option.fullAddress - адрес в виде строки
   * @returns {Promise<null|{[p: string]: *}>}
   */
  async getSuggestData(option) {
    this.pendingStart()

    try {
      const address = await this.gaApp.services.location.api.geocodeStreets(
        option.value,
        option.fullAddress,
      )

      return {
        ...option,
        ...address,
      }
    } catch (error) {
      console.error(error)

      return null
    } finally {
      this.pendingStop()
    }
  }

  /**
   * Обновляет query после выбора подсказки
   * @param text
   */
  updateFromSuggestData({ text }) {
    this.gaApp.services.location.streets.updateQueryTemp(text)
  }
}
