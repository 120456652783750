import { SUGGEST_ABORT_KEY } from '~/modules/location/constants/search'

export class ApiCitiesService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Запрос подсказок по городам в плед
   * @param { string } query - то, что ввел юзер в инпут
   */
  async suggestCities(query) {
    const params = {
      query,
      type: 'city',
    }

    try {
      // отменяем предыдущий запрос
      this.gaApp.api.requestAbort.abortRequest(SUGGEST_ABORT_KEY)

      const { data } = await this.gaApp.repositories.filters.cities.geoSuggest(
        params,
        {
          abortKey: SUGGEST_ABORT_KEY,
        },
      )

      return data
    } catch (error) {
      // не показываем уведомление если это мы отменили запрос
      if (error?.message === SUGGEST_ABORT_KEY) {
        return
      }

      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('filters.search.error.default'),
      )
      throw error
    }
  }

  /*
   * Запрос локации после выбора подсказки с городом
   * */
  async geocodeCities(objectId, fullAddress) {
    const params = {
      objectId,
      type: 'city',
      query: fullAddress,
    }

    try {
      const { data } =
        await this.gaApp.repositories.filters.cities.geoGeocode(params)

      return data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('filters.search.error.default'),
      )
      throw error
    }
  }
}
