import { MODULE_TYPE, PAGE_TYPE } from '../constant'

export const modulePlpSsrRoutes = [
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.LISTING,
    path: '/category/:entityId',
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/plp/page/listing' */
        '~/modules/plp/components/pages/main.vue'
      ),
  },
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.SEARCH,
    path: 'catalogsearch/result',
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/plp/page/listing' */
        '~/modules/plp/components/pages/search.vue'
      ),
  },
]

export const routes = [...modulePlpSsrRoutes]
