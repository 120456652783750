import isObject from 'lodash/isObject'
import isString from 'lodash/isString'

export class ErrorWrapper {
  constructor(error, dataRequest) {
    console.log(error)
    if (isObject(dataRequest)) {
      this.dataRequest = JSON.stringify(dataRequest)
    }
    if (isString(dataRequest)) {
      this.dataRequest = dataRequest
    }

    if (isObject(error.response?.data)) {
      this.dataResponse = JSON.stringify(error.response?.data)
    }
    if (isString(error.response?.data)) {
      this.dataResponse = error.response?.data
    }

    this.stack = error.stack

    this.type = this.name
    this.name = 'ApiRequest'
    this._data = error.response?.data || {}

    // Проверяем error.response.data на доп. вложенность поля data
    const internalErrorData =
      error.response?.data?.data ?? error.response?.data ?? {}

    const {
      reason,
      statusCode,
      statusMessage,
      message,
      localizedMessage,
      id,
      invalidParameters,
    } = internalErrorData

    this.reason = reason
    this.statusCode = statusCode || error.response?.status
    this.statusMessage =
      statusMessage || error.response?.statusText || error.message || ''

    // Иногда могут переписать по месту, иза этого есть дубль statusMessage
    this.message = message || error.response?.statusText || error.message || ''
    this.localizedMessage = localizedMessage
    this.id = id
    this.invalidParameters = invalidParameters
  }

  get data() {
    console.error('No direct accessing to error.data: ', this._data)

    return this._data
  }
}
