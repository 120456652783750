import { useContext } from '@nuxtjs/composition-api'

/**
 * Сервис форматтер для использования в компонентах другого модуля
 * todo: нужен экспорт, который будет работать не только в компонентах, но и в сервисах
 */
export const useLocationFormatter = () => {
  const { $gaApp } = useContext()

  return {
    formatBuildingDetails: (...args) => {
      return $gaApp.services.location.formatter.formatBuildingDetails(...args)
    },
    formatStreetHouse: (...args) => {
      return $gaApp.services.location.formatter.formatStreetHouse(...args)
    },
    formatLocationAddressList: (...args) => {
      return $gaApp.services.location.formatter.formatLocationAddressList(
        ...args,
      )
    },
  }
}
