import { getId } from '@ga/utils'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Запрашиваем начальные данные для страницы в зависимости от ее типа.
   *
   * @param {number} options.page - Номер страницы.
   * @param {Object} options.pageData - Данные для страницы.
   * @param {string} options.categoryId - Идентификатор категории.
   * @param {Object} options.redirectFilters - Фильтры для перенаправления.
   * @return {Promise<Object>} - Promise содержащий начальные данные
   */
  async getInitial({ page, pageData, categoryId, meta, redirectFilters }) {
    // В фоне запрашиваем фильтры в фоне
    this.requestFilters()

    if (this.gaApp.services.plp.main.isPageTypeSearch()) {
      const data = await this.getInitialSearch({
        page,
        pageData,
        redirectFilters,
      })

      return { data }
    }

    if (this.gaApp.services.plp.main.isPageTypeListing()) {
      const data = await this.getInitialCatalog({
        page,
        meta,
        pageData,
        categoryId,
        redirectFilters,
      })

      return { data }
    }
  }

  /**
   * Запрашиваем начальные данные для страницы категории
   *
   * @param {Object} options.redirectFilters - Фильтры из редиректа
   * @param {number} options.page - Номер страницы.
   * @param {string} options.categoryId - Идентификатор категории.
   * @param {Object} [options.pageData={}] - Данные для страницы.
   * @return {Promise<Object>} - Promise содержащий начальные данные категории и продуктов
   */
  async getInitialCatalog({
    redirectFilters,
    page,
    categoryId,
    meta,
    pageData = {},
  }) {
    const [products, category] = await Promise.allSettled([
      this.fetchProductsByPage({ filters: redirectFilters, page, pageData }),
      this.gaApp.repositories.plp.main.getCategory({
        categoryId,
        ...(meta ? { meta } : {}),
      }),
    ])

    return {
      category: category.value.data,
      products: products.value.data,
    }
  }

  /**
   * Запрашиваем начальные данные для поисковой выдачи
   *
   * @param {Object} options.redirectFilters - Фильтры из редиректа
   * @param {number} options.page - Номер страницы.
   * @param {string} options.categoryId - Идентификатор категории.
   * @param {Object} [options.pageData={}] - Данные для страницы.
   * @return {Promise<Object>} - Promise содержащий начальные данные поисковой выдачи
   */
  async getInitialSearch({ page, redirectFilters, pageData = {} }) {
    const { data } = await this.fetchProductsByPage({
      pageData,
      filters: redirectFilters,
      page,
    })

    const {
      correction,
      placements,
      redirect,
      products,
      count,
      marketingProducts,
    } = data

    return {
      correction,
      placements,
      products: {
        products,
        count,
        marketingProducts,
      },
      redirect,
      query: pageData.query,
    }
  }

  /**
   * Начальная подготовка данных поиска или каталога
   *
   * @param {string} options.entityId - Идентификатор категории.
   * @param {Object} options.filters - Фильтры из редиректа.
   * @param {Object} options.seo - SEO-информация.
   * @return {Promise<Object>} - Промис, который разрешается в данные листинга.
   */
  async getListing({
    entityId: categoryId,
    filters: redirectFilters,
    meta,
    seo,
  }) {
    const { id: cityId } =
      this.gaApp.services.location.main.getDeliveryAddress()

    const page = this.gaApp.stores.filters.main.getPageNumberCurrent

    const cacheData = this.gaApp.services.cache.main.getServerData('plp')

    const pageData = this.gaApp.stores.plp.main.pageData

    const { data } =
      cacheData ||
      (await this.getInitial({
        page,
        pageData,
        categoryId,
        meta,
        redirectFilters,
      }))

    // Запросим слоты брендзоны, если это листинг
    // А так же установим тему хедера
    this.setBrandzoneSlots(data.category)

    // может вернуться при запросе поисковой выдачи
    if (data.redirect) {
      this.gaApp.redirect(data.redirect)
      await new Promise(() => {})
    }

    // Устанавливаем данные во временное хранилище
    this.gaApp.stores.plp.main.setListingTempData({
      data,
      cityId,
      pageNumber: page,
      redirectFilters,
      seo,
      ...pageData.params,
    })

    if (this.gaApp.services.plp.main.isPageTypeSearch()) {
      // айди нужен для рефреша пейджа результатов поиска
      // при изменении $route.query.q
      this.gaApp.stores.plp.main.setSearchResultId({
        id: getId(),
      })
      this.gaApp.stores.plp.main.setSearchPlacements(data.placements)
      this.gaApp.stores.plp.main.setSearchQueryCorrected(data.correction)
    }

    return data
  }

  /**
   * Запрос фильтров
   */
  async requestFilters() {
    const {
      id: cityId,
      cityDistrict,
      geoPolygons,
    } = this.gaApp.services.location.main.getDeliveryAddress()

    this.gaApp.stores.filters.main.setFiltersPending(true)

    const customerGroupId = this.gaApp.stores.user.main.data.groupId
    const filtersSelected = this.gaApp.stores.filters.main.filtersSelected

    const pageData = this.gaApp.stores.plp.main.pageData

    this.gaApp.stores.filters.main.setFiltersHash({
      ...pageData.params,
      cityId,
      filters: filtersSelected,
    })

    const response = await this.gaApp.repositories.plp.main.fetchFilters(
      {
        ...pageData.params,

        cityId,
        cityDistrict,
        geoPolygons,

        filters: filtersSelected,

        ...(customerGroupId ? { customerGroupId } : {}),
      },
      pageData.type,
    )

    const { analytics, filters, countSelectedFilters, productsCount } =
      response.data

    // TODO:  Удалить вызов exlcudeFiltersValuesByFeatureToggle, когда избавимся от ФТ showSortByRating
    this.gaApp.stores.filters.main.setFilters(
      this.gaApp.services.plp.main.exlcudeFiltersValuesByFeatureToggle(filters),
    )
    this.gaApp.stores.filters.main.setCountProductsFiltered(productsCount)
    this.gaApp.stores.filters.main.setAnalytics(analytics)
    this.gaApp.stores.filters.main.setCountSelectedFilters(countSelectedFilters)

    this.gaApp.stores.filters.main.setFiltersPending(false)

    return response
  }

  /**
   * Запрос списка продуктов
   */
  async fetchProductsByPage({ pageData = {}, filters = {}, page = 1 }) {
    const {
      id: cityId,
      cityDistrict,
      geoPolygons,
    } = this.gaApp.services.location.main.getDeliveryAddress()

    const customerGroupId = this.gaApp.stores.user.main.data.groupId

    const cacheData = this.gaApp.services.cache.main.getServerData('products')

    return (
      cacheData ||
      (await this.gaApp.repositories.plp.main.fetchProducts(
        {
          ...pageData.params,
          cityId,
          cityDistrict,
          geoPolygons,

          pageNumber: page,

          filters,

          ...(customerGroupId ? { customerGroupId } : {}),
        },
        pageData.type,
      ))
    )
  }

  /**
   * Запрос блоков брендзоны
   */
  async fetchBrandzoneSlots() {
    const { data } = await this.getSlots()

    this.gaApp.stores.plp.main.setBrandzoneSlots(data)

    return data
  }

  setBrandzoneSlots(category) {
    // Для листинга заспрашиваем слоты брендзоны и устанавливаем тему хедера
    if (this.gaApp.services.plp.main.isPageTypeListing()) {
      this.fetchBrandzoneSlots()
        .then((slots) => {
          // Устанавливаем тему хедера, только после ответа
          this.gaApp.services.plp.main.setHeaderColorTheme({
            category,
            slots,
          })
        })
        .catch(() => {
          this.gaApp.stores.plp.main.setBrandzoneSlots(null)
          this.gaApp.services.plp.main.setHeaderColorTheme({ category })
        })
    }

    // Для поисковой выдачи устанавливаем тему хедера
    if (this.gaApp.services.plp.main.isPageTypeSearch()) {
      this.gaApp.stores.plp.main.setBrandzoneSlots(null)
      this.gaApp.services.plp.main.setHeaderColorTheme()
    }
  }

  /**
   * Нужно для того, что бы получить слоты БЗ для подкатегории БЗ.
   * Например logobar
   */
  async getSlots() {
    if (
      this.gaApp.features.get('bzSlotsV2') ||
      this.gaApp.features.get('mergingJSONandWidgetsForBZ')
    ) {
      // Проверям, является ли категория брендзоной
      const { data } = await this.gaApp.repositories.plp.main.checkBrandzone({
        categoryId: this.gaApp.stores.plp.main.pageData.params.categoryId,
      })

      // Если является, то запрашиваем слоты брендзоны
      if (data.isBrandZone && data.brandMainCategoryId) {
        const categoryId = data.brandMainCategoryId
        return this.gaApp.features.get('mergingJSONandWidgetsForBZ')
          ? this.gaApp.repositories.plp.main.getSlotsV3({
              categoryId,
            })
          : this.gaApp.repositories.plp.main.getSlotsV2({
              categoryId,
            })
      }

      // Если не является, то возвращаем null
      return {
        data: null,
      }
    }

    return this.gaApp.repositories.plp.main.getSlots({
      brandUrl: this.gaApp.route.path,
    })
  }
}
