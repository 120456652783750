import { BANNER_TYPES, BLOCK_TYPES, SLOT_COMPONENTS } from '../constants'

export class SlotsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /** заполняем все сторы блоков */
  fillStores() {
    this.gaApp.stores.brandzone.slots.items.forEach((block) => {
      this.fillStoresBlockData(block)
    })
  }

  /** проверяем есть ли нужный стор и добавляем/обновляем данные блока в зависимости от типа */
  fillStoresBlockData(block) {
    const type = this.getType(block)

    // проверяем есть ли нужный стор
    if (this.gaApp.stores.brandzone[type]) {
      // вызываем метод fillStore соответствующего сервиса для заполнения его стора
      this.gaApp.services.brandzone[type].fillStore(block)
    }
  }

  getType(block) {
    switch (true) {
      case Object.values(BANNER_TYPES).includes(block.type):
        return BLOCK_TYPES.BANNER
      case block.type === BLOCK_TYPES.LOGOBAR:
        return BLOCK_TYPES.LOGO

      default:
        return block.type
    }
  }

  getSlotComponent(block) {
    const type = this.getType(block)

    switch (type) {
      /**
       * Тут можно написать case для выбора компонента слота на основе FT
       * Например:
       *
       *  case BLOCK_TYPES.COMPONENT:
       *    return this.gaApp.features.get('componentFeatureV2')
       *      ? SLOT_COMPONENTS[BLOCK_TYPES.COMPONENT_V2]
       *      : SLOT_COMPONENTS[BLOCK_TYPES.COMPONENT]
       */

      default:
        return SLOT_COMPONENTS[type]
    }
  }

  async fetchSlotsData() {
    const { data } = await this.gaApp.services.brandzone.api.fetchSlots()

    this.gaApp.services.brandzone.page.setDataOnPageCreated(data)
  }

  async refetchSlotsData() {
    const { data } = await this.gaApp.services.brandzone.api.fetchSlots()

    this.gaApp.services.app.scheduler.postTask(() => {
      this.gaApp.stores.brandzone.slots.items = data.blocks
      this.fillStores()
    })
  }

  resetAll() {
    this.gaApp.stores.brandzone.slots.$reset()

    this.gaApp.services.brandzone.slider.reset()
    this.gaApp.services.brandzone.products.reset()
    this.gaApp.services.brandzone.logo.reset()
    this.gaApp.services.brandzone.listing.reset()
    this.gaApp.services.brandzone.banner.reset()
    this.gaApp.services.brandzone.category.reset()
    this.gaApp.services.brandzone.categoriesTiles.reset()
  }
}
