import { HEADING_MARGIN_SIZE } from '~/modules/articles/constants/editor'

import { EDITOR_BLOCK_TYPES } from '../../constants/editor'

import { BLOCK_VIEWS, BLOCK_VIEWS_BY_TYPES } from './block-views'
import { CHUNK_TRANSITIONS, CHUNK_TYPE } from './chunk-transitions'

/**
 * Функция перегруппировывает блоки исходных секций для дизайна в чанки.
 * @param {Object} sections
 */
export const sectionChunks = (section) => {
  const chunks = []
  let imageCount = 0
  let productCardCount = 0
  let currentChunkType = CHUNK_TYPE.EMPTY

  section.blocks.forEach((block, index, array) => {
    const blockView = BLOCK_VIEWS_BY_TYPES[block.type] ?? BLOCK_VIEWS.NARROW

    const newChunkType = CHUNK_TRANSITIONS[currentChunkType][blockView]

    let newBlock = block

    // Так как происходит разбиение секции на чанки
    // нужно заранее определить позицию вертикальной картинки
    if (block.type === EDITOR_BLOCK_TYPES.IMAGE && block.data.isVerticalImage) {
      newBlock = addVerticalImagePosition({ block, imageCount })
      imageCount++
    }

    // Так как происходит разбиение секции на чанки
    // нужно заранее определить разворот карточки - reversed
    if (block.type === EDITOR_BLOCK_TYPES.PRODUCT_CARD) {
      newBlock = configureProductCardReversedModifier({
        block,
        productCardCount,
      })
      productCardCount++
    }

    // Определить группу из заголовков - h3+h4+h5
    if (block.type === EDITOR_BLOCK_TYPES.HEADER) {
      newBlock = checkHeadingChunk(block, index, array)
    }

    if (newChunkType === currentChunkType) {
      const lastChunk = chunks[chunks.length - 1]
      if (lastChunk) lastChunk.blocks.push(newBlock)
    } else {
      chunks.push({
        type: newChunkType,
        blocks: [newBlock],
      })
    }

    currentChunkType = newChunkType
  })

  return chunks
}

// Задать заголовку h3 увеличенный нижний отступ, так как он находится в связке с заголовками h4 и h5.
// По умолчанию отступ между h3 и h4 = 15px,
// в случае связки h3+h4+h5, отступ между h3 и h4 = 20px - требования дизайна
const checkHeadingChunk = (block, index, array) => {
  const isSectionHeader = block.data.isSectionHeader
  const isHeadingThird = block.data.level === 3
  const hasTwoNextBlocks = array.length > index + 2

  // Если это заголовок секции, или заголовок отличен от h3, или далее менее 2-х блоков
  if (isSectionHeader || !isHeadingThird || !hasTwoNextBlocks) {
    return block
  }

  // если это h3 - проверить что далее идут h4 и h5
  const firstNextBlock = array[index + 1]
  const secondNextBlock = array[index + 2]

  const isFirstNextBlockHeadingFour =
    firstNextBlock.type === EDITOR_BLOCK_TYPES.HEADER &&
    firstNextBlock.data.level === 4

  const isSecondNextBlockHeadingFive =
    secondNextBlock.type === EDITOR_BLOCK_TYPES.HEADER &&
    secondNextBlock.data.level === 5

  if (!isFirstNextBlockHeadingFour || !isSecondNextBlockHeadingFive) {
    return block
  }

  // Нашли связку из заголовков h3 + h4 + h5
  return {
    ...block,
    data: {
      ...block.data,
      marginBottomSize: HEADING_MARGIN_SIZE.LARGE,
    },
  }
}

const addVerticalImagePosition = ({ block, imageCount }) => {
  const imagePosition = imageCount % 2 === 0 ? 'start' : 'end'

  return {
    ...block,
    data: {
      ...block.data,
      imagePosition,
    },
  }
}

const configureProductCardReversedModifier = ({ block, productCardCount }) => {
  return {
    ...block,
    data: {
      ...block.data,
      reversed: productCardCount % 2 === 1,
    },
  }
}
