import * as components from './components'
import { locales } from './locales'
import { repositories } from './repositories'
import { services } from './services'
import { stores } from './stores'

export { GaApp } from './core/app'

export {
  useAppRouter,
  useAppsStores,
  useAppWindowStore,
  usePrefetchRedirect,
  useVideoAutoplay,
  useAppCommonStore,
} from './composables'

export { APP_EVENT } from './constant'

export { useApp } from './composables/app'

export const app = {
  meta: {
    name: 'app',
    locales,
  },
  components,
  layers: {
    stores,
    services,
    repositories,
  },
}

export { mediaQueryInstance } from './helpers/mediaQuery'
