import get from 'lodash/get'
import set from 'lodash/set'

import { doubleRAF } from '@ga/shared-browser'
import { getId } from '@ga/utils'

import { initFormData, isValidIdOptions } from '~/modules/gift-cards/helpers'
import { errors } from '~/modules/gift-cards/repositories/errors'

import {
  DESIGN_TYPES,
  LOADER,
  MAPS,
  PAYMENT_METHODS,
  STEPS,
} from '../constants/digital'
import { HASH_PARAM } from '../constants/hash'

export class DigitalService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Заполнение стора конфигурации оформления ЭПК
   * @param {object} data данные конфигурации из BFF
   */
  fillConfigData(data) {
    this.setImages(data.foregrounds)
    this.setDefaultForegroundId(data.foregrounds)
    this.setBackgrounds(data.backgrounds)
    this.setDefaultBackgroundId(data.backgrounds)
    this.setPrices(data.prices)
    this.setTimezones(data.timezones)
    this.setValidationRules(data.rules)
    this.setThematics(data.thematics)
  }

  /**
   * Получение конфигурации оформления ЭПК.
   *
   * Получает и заполняет конфигурацию дизайна GA и флоу оформления ЭПК.
   */
  async getDigitalConfig() {
    const { data } = await this.gaApp.services.giftCards.api.getConfig()

    this.fillConfigData(data)
  }

  /**
   * Загружает конфигурацию оформления ЭПК со всеми типами дизайнов.
   *
   * Во время загрузки отображает спиннер на модальном окне, получает и заполняет конфигурацию для всех типов дизайнов
   */
  async fetchConfigData() {
    this.toggleLoader({
      status: true,
      type: LOADER.CONFIG,
    })

    try {
      const promises = [this.getDigitalConfig()]

      if (this.gaApp.features.get('giftCardsDesignTypes')) {
        // повторный запрос конфигурации нейросети
        promises.push(this.gaApp.services.giftCards.digitalAiDesign.getConfig())
        // запрос конфигурации дизайна нейросети
        promises.push(
          this.gaApp.services.giftCards.digitalAiDesign.getSavedDesigns(),
        )
      }

      await Promise.all(promises)
    } catch (error) {
      await this.resetModal()
      this.gaApp.services.giftCards.main.redirectToErrorPage({
        statusCode: 500,
        message: errors.getConfig,
      })
    } finally {
      this.toggleLoader({
        status: false,
        type: LOADER.CONFIG,
      })
    }
  }

  /**
   * Запускает оплату подарочных карт банковской картой
   */
  async sendCardPayment() {
    this.toggleLoader({
      status: true,
      type: LOADER.SUBMIT,
    })

    if (
      !this.gaApp.stores.giftCards.digital.basketId ||
      this.gaApp.stores.giftCards.digital.lastModifyFormData
    ) {
      this.setBasketId()
    }

    try {
      const submitData = this.gaApp.services.giftCards.dto.order.getOrderData()

      const { data } =
        await this.gaApp.services.giftCards.api.paymentOrderCard(submitData)

      this.gaApp.services.giftCards.payment.setOrderId(data.salesId)

      this.gaApp.services.giftCards.main.redirectToExternalUrl(data.paymentUrl)
    } catch (error) {
      const newError = new Error('Ошибка оплаты картой')
      newError.errorData = JSON.stringify(error)
      this.gaApp.services.app.apm.captureError(newError)

      this.gaApp.services.giftCards.main.handleServerErrors(error)

      throw new Error(errors.paymentOrderCard)
    } finally {
      this.updateAtFormData(0)
      this.toggleLoader({
        status: false,
        type: LOADER.SUBMIT,
      })
    }
  }

  /**
   * Фасад выбора способа оплаты.
   *
   * @deprecated Если планируется добавления нового способа оплаты. Используйте сервис gaApp.services.giftCards.payments* и его дочерние сервисы.
   */
  sendOrder() {
    const typePayment = this.gaApp.stores.giftCards.digital.activeOptionPayment

    switch (typePayment) {
      case PAYMENT_METHODS.CARD:
        return this.sendCardPayment()
      default:
        return this.sendCardPayment()
    }
  }

  /**
   * Сбрасывает состояние оформления ЭПК
   */
  resetModal() {
    return new Promise((resolve) => {
      this.toggleVisibleInnerModal(false)
      this.toggleVisibleModal(false)
      this.setStep(0)

      doubleRAF(() => {
        resolve(true)
      })
    })
  }

  /**
   * Устанавливает активный шаг оформления ЭПК
   * @param {number} value номер шага формы оформления ЭПК
   */
  setStep(value) {
    this.gaApp.stores.giftCards.digital.currentStep = value

    this.setVisitedSteps(value)
    this.updateAt()
  }

  /**
   * Переход к предыдущему шагу оформления ЭПК
   */
  setPrevStep() {
    this.setStep(this.gaApp.stores.giftCards.digital.currentStep - 1)
  }

  /**
   * Переход к следующему шагу оформления ЭПК
   */
  setNextStep() {
    this.setStep(this.gaApp.stores.giftCards.digital.currentStep + 1)
  }

  /**
   * Переключает видимость модального окна оформления ЭПК
   * @param {boolean} value значение видимости модального окна оформления ЭПК.
   * Где `true` — видимо,
   * `false` — скрыто
   */
  toggleVisibleModal(value) {
    this.gaApp.stores.giftCards.digital.visibleModal = value
  }

  /**
   * Видимость формы оформления ЭПК
   * @param {boolean} value значение видимости модального окна оформления ЭПК.
   * Где `true` — видимо,
   * `false` — скрыто
   */
  toggleVisibleInnerModal(value) {
    this.gaApp.stores.giftCards.digital.visibleInnerModal = value
  }

  /**
   * Открывает модальное окно оформления ЭПК с определенным шагом
   * @param {number} params номер шага оформления ЭПК
   */
  openModalWithStep(params) {
    if (this.gaApp.stores.giftCards.digital.visibleModal) {
      return undefined
    }

    const step = Number(params?.step) || 0

    this.toggleVisibleModal(true)

    if (this.gaApp.stores.giftCards.digital.visitedSteps.includes(step)) {
      this.setStep(step)
    }
  }

  /**
   *
   * @param {object} options переключение вкладок оплаты заказа
   * @deprecated Метод более не участвует выборе способа оплаты ЭПК. Используйте сервис gaApp.services.giftCards.payments* и его дочерние сервисы.
   */
  changeTab({ value, tab }) {
    this.gaApp.stores.giftCards.digital[tab] = value

    this.updateAt()
    this.updateAtFormData()
  }

  /**
   * Обновление времени редактирования данных
   *
   * Использовать только в "чувствительных" мутациях
   */
  updateAt(timestamp) {
    this.gaApp.stores.giftCards.digital.lastModify = timestamp ?? Date.now()
  }

  /**
   * Обновление времени редактирования данных формы
   * @param {string | number | Date} timestamp временная метка последнего редактирования данных
   */
  updateAtFormData(timestamp) {
    this.gaApp.stores.giftCards.digital.lastModifyFormData =
      timestamp ?? Date.now()
  }

  /**
   * Обновление данных заказа ЭПК.
   *
   * Используется для изменения данных и обновления времени последнего редактирования.
   * @param {object} options конфигурация обновления данных поля
   */
  setValueField({ fieldName, value, step }) {
    this.gaApp.stores.giftCards.digital.formData[
      step ?? this.gaApp.stores.giftCards.digital.currentStep
    ][fieldName] = value

    this.updateAt()
    this.updateAtFormData()
  }

  /**
   * Устанавливает доступные для выбора фоны оформления ЭПК
   * @param {object} backgrounds конфигурация фонов ЭПК
   */
  setBackgrounds(backgrounds) {
    const { activeId, options } = backgrounds

    this.gaApp.stores.giftCards.digital.design[
      DESIGN_TYPES.GA
    ].backgroundsOptions = options

    const activeIdFromState = get(
      this.gaApp.stores.giftCards.digital,
      MAPS.COLOR,
    )
    if (
      !this.gaApp.stores.giftCards.digital.isRestored ||
      !isValidIdOptions(activeIdFromState, options)
    ) {
      set(this.gaApp.stores.giftCards.digital, MAPS.COLOR, activeId)
    }
  }

  /**
   * Устанавливает id дефолтного фона
   * @param {object} images конфигурация фонов ЭПК
   */
  setDefaultBackgroundId(backgrounds) {
    const { activeId } = backgrounds

    this.gaApp.stores.giftCards.digital.defaultBackgroundId = activeId
  }

  /**
   * Устанавливает доступные для выбора обложки оформления ЭПК
   * @param {object} images конфигурация обложек ЭПК
   */
  setImages(images) {
    const { activeId, options } = images

    this.gaApp.stores.giftCards.digital.design[DESIGN_TYPES.GA].imagesOptions =
      options

    const activeIdFromState = get(this.gaApp.stores.giftCards.digital, MAPS.IMG)

    if (
      !this.gaApp.stores.giftCards.digital.isRestored ||
      !isValidIdOptions(activeIdFromState, options)
    ) {
      set(this.gaApp.stores.giftCards.digital, MAPS.IMG, activeId)
    }
  }

  /**
   * Устанавливает id дефолтной обложки
   * @param {object} images конфигурация обложек ЭПК
   */
  setDefaultForegroundId(images) {
    const { activeId } = images

    this.gaApp.stores.giftCards.digital.defaultForegroundId = activeId
  }

  /**
   * Устанавливает доступные для выбора номиналы ЭПК
   * @param {object} prices конфигурация номиналов ЭПК
   */
  setPrices(prices) {
    const { activeId, options, currency, denominator } = prices

    this.gaApp.stores.giftCards.digital.pricesOptions = options
    this.gaApp.stores.giftCards.digital.priceInfo = {
      currency,
      denominator,
    }

    if (
      !this.gaApp.stores.giftCards.digital.isRestored ||
      !get(this.gaApp.stores.giftCards.digital, MAPS.AMOUNT)
    ) {
      set(this.gaApp.stores.giftCards.digital, MAPS.AMOUNT, String(activeId))
    }
  }

  /**
   * Устанавливает доступные часовые пояса для выбора времени отправки ЭПК
   * @param {object} timezones Конфигурация часовых поясов
   */
  setTimezones(timezones) {
    const { activeId, options } = timezones

    this.gaApp.stores.giftCards.digital.timezoneOptions = options

    set(this.gaApp.stores.giftCards.digital, MAPS.TIMEZONE, activeId)
  }

  /**
   * Установка правил валидации
   * @param {object} rules правила для валидации формы оформления ЭПК
   */
  setValidationRules(rules) {
    this.gaApp.stores.giftCards.digital.validationRules = {
      ...this.gaApp.stores.giftCards.digital.validationRules,
      ...rules,
    }
  }

  /**
   * Установка тематик для фильтрации дизайна
   */
  setThematics(thematics) {
    if (!thematics) return

    this.showAllThematics()

    this.gaApp.stores.giftCards.digital.thematics = [
      this.gaApp.stores.giftCards.digital.allThematics,
      ...thematics.map((el) => {
        return {
          value: el.thematicId,
          label: el.name,
          filters: {
            backgrounds: el.backgrounds,
            foregrounds: el.foregrounds,
          },
        }
      }),
    ]
  }

  /**
   * Устанавливает идентификатор корзины.
   *
   * @param {string} id идентификатор корзины. Если не указан, то генерируется новый идентификатор
   */
  setBasketId(id) {
    this.gaApp.stores.giftCards.digital.basketId = id ?? getId()
  }

  /**
   * Изменяет отображение индикаторов загрузки формы ЭПК
   * @param {object} param0 конфигурация лоудера
   */
  toggleLoader({ status, type }) {
    this.gaApp.stores.giftCards.digital.loader[type] = status
  }

  /**
   * Устанавливает выбранный фон оформления ЭПК и обновляет время изменения данных
   * @param {string} backgroundId идентификатор фона оформления ЭПК
   */
  changeBackground(backgroundId) {
    set(this.gaApp.stores.giftCards.digital, MAPS.COLOR, backgroundId)

    this.updateAt()
    this.updateAtFormData()
  }

  /**
   * Устанавливает выбранную обложку оформления ЭПК и обновляет время изменения данных
   * @param {string} imageId идентификатор обложки оформления ЭПК
   */
  changeImage(imageId) {
    set(this.gaApp.stores.giftCards.digital, MAPS.IMG, imageId)

    this.updateAt()
    this.updateAtFormData()
  }

  /**
   * Устанавливает выбранную тематику дизайна
   * @param {string} id идентификатор тематики
   */
  setActiveThematicVal(id) {
    this.gaApp.stores.giftCards.digital.activeThematicVal = id
  }

  /**
   * Устанавливает значение для сброса фильтрации дизайнов (= показать все дизайны)
   */
  showAllThematics() {
    const allOption = {
      value: getId(),
      label: this.gaApp.i18n.t('giftCards.stepLine.thematics.showAllLabel'),
    }

    // по умолчанию активная тематика = показать все
    this.setActiveThematicVal(allOption.value)
    this.gaApp.stores.giftCards.digital.allThematics = allOption
  }

  /**
   * Устанавливает выбранный номинал оформления ЭПК и обновляет время изменения данных
   * @param {string} id идентификатор номинала ЭПК
   */
  changePrice(id) {
    set(this.gaApp.stores.giftCards.digital, MAPS.AMOUNT, String(id))

    this.updateAt()
    this.updateAtFormData()
  }

  /**
   * Устанавливает выбранный шаг как "отображенный"
   * @param {number} step номер посещенного шага
   */
  setVisitedSteps(step = 0) {
    if (!this.gaApp.stores.giftCards.digital.visitedSteps.includes(step)) {
      this.gaApp.stores.giftCards.digital.visitedStepsByDesign[
        this.gaApp.stores.giftCards.digital.selectedDesignType
      ].push(step)
    }
  }

  /**
   * Включает отображение формы поздравления получателя
   */
  setVisibleRecipientField() {
    this.gaApp.stores.giftCards.digital.isHideRecipientFields = false

    this.updateAt()
  }

  /**
   * Устанавливает телефонный код выбранной страны
   * @param {string} code код страны
   */
  setPhoneCode(code) {
    this.gaApp.stores.giftCards.digital.currentPhoneCode = code
  }

  /**
   * Устанавливает ближайшую доступную дату отложенной отправки ЭПК
   */
  setCurrentDate() {
    this.gaApp.stores.giftCards.digital.currentDate = this.gaApp.libs
      .dateWrapper()
      .second(0)
      .millisecond(0)
      .toISOString()
  }

  /**
   * Устанавливает дату получения ЭПК
   * @param {boolean} hourAhead необходимо ли установить время на час вперед
   */
  setDate(hourAhead = false) {
    if (hourAhead) {
      const dateCurrent = this.gaApp.libs.dateWrapper(
        this.gaApp.stores.giftCards.digital.currentDate,
      )
      const dateHourAhead = dateCurrent.add(1, 'hour').minute(0).toISOString()

      set(this.gaApp.stores.giftCards.digital, MAPS.DATE, dateHourAhead)
    } else {
      set(
        this.gaApp.stores.giftCards.digital,
        MAPS.DATE,
        this.gaApp.stores.giftCards.digital.currentDate,
      )
    }
  }

  /**
   * Устанавливает видимость формы получателя
   * @param {boolean} visible видимость формы получателя.
   * Где `true` — видимо,
   * `false` — скрыто
   */
  toggleReceiptForm(visible) {
    this.gaApp.stores.giftCards.digital.showReceiptForm = visible
  }

  /**
   * Сброс данных формы ЭПК и обновление времени редактирования
   */
  resetFormData() {
    this.gaApp.stores.giftCards.digital.formData = initFormData()

    this.updateAt()
  }

  /**
   * Сброс стора ЭПК
   */
  resetState() {
    this.gaApp.stores.giftCards.digital.$reset()
  }

  /**
   * Возвращает параметры из хеша
   * @param {string} hash содержимое хеша роута
   */
  parseHash(hash) {
    const params = new URLSearchParams(hash.split('?')[1])
    const step = Number(params.get(HASH_PARAM.STEP)) || STEPS.DESIGN
    const prompt = Boolean(params.get(HASH_PARAM.PROMPT))

    return {
      step,
      prompt,
    }
  }

  // TODO убрать логику с закрытием модалки, с парсингом хэша после удаления модалки, переименовать метод в changeByQuery, переименовать value в query
  /**
   * Изменяет состояние в зависимости от хэша
   * @param {(string | Object)} value строка, содержащая хэш или объект квери роута
   */
  changeByUrlValue(value) {
    const isDigitalCardsPath =
      this.gaApp.route.fullPath.includes('cards/digital')

    if (
      !isDigitalCardsPath &&
      this.gaApp.stores.giftCards.digital.visibleModal &&
      !this.gaApp.services.giftCards.main.validHash(value)
    ) {
      return this.toggleVisibleModal(false)
    }

    const { step } = isDigitalCardsPath
      ? { step: Number(value.step) }
      : this.parseHash(value)
    const { prompt } = isDigitalCardsPath ? value : this.parseHash(value)

    const currentStep = this.gaApp.stores.giftCards.digital.currentStep

    const isForwardAllowed =
      step > currentStep &&
      this.gaApp.stores.giftCards.digital.visitedSteps.includes(step)
    const isBackAllowed = step < currentStep

    if (((step || step === 0) && isForwardAllowed) || isBackAllowed) {
      this.setStep(step)
    }

    if (prompt && step === STEPS.DESIGN) {
      this.gaApp.services.giftCards.digitalDesignTypes.setDesignType(
        DESIGN_TYPES.AI,
      )
      this.gaApp.services.giftCards.digitalAiDesign.openPromptModal()
    } else {
      this.gaApp.services.giftCards.digitalAiDesign.closePromptModal()
    }
  }
}
