import isEqual from 'lodash/isEqual'

import { FILTER_PAGE_NUMBER } from '~/modules/filters/constant'

import { CLEAR_PAGES, PAGINATION_DIRECTION } from '../constant'

export class ListingService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  areHashesEqual() {
    const productHash = this.gaApp.stores.plp.main.productsHash
    const filtersHash = this.gaApp.stores.filters.main.filtersHash

    return isEqual(filtersHash, productHash)
  }

  getCategoryData(category, seo) {
    const params = this.gaApp.stores.plp.main.pageData?.params ?? {}
    const hasQueryAndMeta = params.query && params.meta

    if (hasQueryAndMeta) {
      const name = seo.header ?? params.query

      return { ...category, breadcrumbs: [], name, seo }
    } else {
      return { ...category, seo }
    }
  }

  /**
   * Забирает данные из стора, которые были получены от редирект-сервиса,
   * и заполняет стор на created plp
   */
  setListingData() {
    const listingTempData = this.gaApp.stores.plp.main.getListingTempData

    if (!listingTempData) {
      return false
    }

    const pageData = this.gaApp.stores.plp.main.pageData

    const {
      data,
      cityId,
      pageNumber,
      redirectFilters: redirectFiltersSource,
      seo,
    } = listingTempData

    // TODO: Удалить вызов exlcudeRedirectFiltersByFeatureToggle, когда избавимся от ФТ showSortByRating
    const redirectFilters =
      this.gaApp.services.plp.main.exlcudeRedirectFiltersByFeatureToggle(
        redirectFiltersSource,
      )

    /**
     * Категория
     */
    this.gaApp.stores.plp.main.setCategoryData(
      this.getCategoryData(data.category, seo),
    )

    /**
     * Фильтры
     *
     * data.filters может существовать только в случае, если данные пришли из кеша.
     * в противном случае, фильтры будут запрошены отдельным запросом.
     */
    if (data.filters) {
      const {
        analytics,
        filters: filtersAll,
        countSelectedFilters,
        productsCount,
      } = data.filters

      // TODO: Удалить вызов exlcudeFiltersValuesByFeatureToggle, когда избавимся от ФТ showSortByRating
      this.gaApp.stores.filters.main.setFilters(
        this.gaApp.services.plp.main.exlcudeFiltersValuesByFeatureToggle(
          filtersAll,
        ),
      )
      this.gaApp.stores.filters.main.setFiltersSelected(redirectFilters)
      this.gaApp.stores.filters.main.setCountSelectedFilters(
        countSelectedFilters,
      )
      this.gaApp.stores.filters.main.setCountProductsFiltered(productsCount)
      this.gaApp.stores.filters.main.setFiltersHash({
        ...pageData.params,
        cityId,
        filters: redirectFilters,
      })
      this.gaApp.stores.filters.main.setAnalytics(analytics)
    }

    /**
     * Продукты
     */
    const { marketingProducts, products, count } = data.products

    this.gaApp.stores.plp.main.setProductsCount(count)
    this.gaApp.stores.plp.main.setProductsHash({
      ...pageData.params,
      cityId,
      filters: redirectFilters,
    })
    this.gaApp.stores.plp.main.setPage({
      number: pageNumber,
      products: this.normalize(products, pageNumber),
      marketingProducts,
    })

    /**
     * Очищаем данные в сторе, которые были получены от редирект-сервиса
     */
    const clearListingTempData = () =>
      this.gaApp.stores.plp.main.clearListingTempData()
    this.gaApp.services.app.scheduler.postTask(clearListingTempData)
  }

  /**
   * Запрос списка продуктов при изменении сортировки
   */
  async refreshSortingAndProducts({ categoryId, cityId, sorting }) {
    this.gaApp.stores.plp.main.clearPages()

    await Promise.all([
      this.gaApp.services.filters.main.pageNumber.updateFilter(1),

      this.gaApp.services.filters.main.updateFilter({
        categoryId,
        cityId,
        filter: { ...sorting },
      }),

      this.fetchProducts(),
    ])
  }

  async fetchProducts(
    page = 1,
    direction = PAGINATION_DIRECTION.NEXT,
    clearPages = CLEAR_PAGES.NONE,
  ) {
    const filters = this.gaApp.stores.filters.main.filtersSelected
    const pageData = this.gaApp.stores.plp.main.pageData

    const cityId = this.gaApp.services.location.main.getDeliveryAddress()?.id
    const categoryId = this.gaApp.stores.plp.main.getCategoryData?.id

    this.gaApp.stores.plp.main.setPagination({
      direction,
      value: {
        pending: true,
        error: false,
      },
    })

    if (clearPages === CLEAR_PAGES.BEFORE_REQUEST) {
      this.gaApp.stores.plp.main.clearPages()
    }

    try {
      /**
       * Устанавливаем количество выбранных фильтров перед запросом,
       * что бы обновить счетчик не дожидаясь ответа запроса продуктов
       *
       * После получение ответа запроса продуктов актуализируем значение
       * выбранных фильтров значением из тела ответа
       */
      this.gaApp.stores.filters.main.setCountSelectedFilters(
        this.gaApp.stores.filters.main.getFiltersMainSelected.length,
      )

      const { data } = await this.gaApp.services.plp.api.fetchProductsByPage({
        page,
        cityId,
        filters,
        pageData,
        categoryId,
      })

      if (clearPages === CLEAR_PAGES.AFTER_REQUEST) {
        this.gaApp.stores.plp.main.clearPages()
      }

      const { products, count, marketingProducts, countSelectedFilters } = data

      this.gaApp.stores.plp.main.setProductsCount(count)

      this.gaApp.stores.plp.main.setPage({
        id: new Date().getTime(),
        number: page,
        products: this.normalize(products, page),
        marketingProducts,
      })

      if (this.gaApp.services.plp.main.isPageTypeSearch()) {
        this.gaApp.stores.plp.main.setSearchPlacements(data.placements)
        if (data.products?.length) {
          this.gaApp.analytics.modules.plp.onSearchProducts(data.products, page)
        }
      }

      const setProductsHash = () =>
        this.gaApp.stores.plp.main.setProductsHash({
          ...pageData.params,
          cityId,
          filters,
        })

      /**
       * Актуализируем значение выбранных фильтров значением из тела ответа
       */
      const setCountSelectedFilters = () =>
        this.gaApp.stores.filters.main.setCountSelectedFilters(
          countSelectedFilters,
        )

      this.gaApp.services.app.scheduler.postTasks([
        { task: setProductsHash },
        { task: setCountSelectedFilters },
      ])
    } catch (error) {
      this.gaApp.stores.plp.main.setPagination({
        direction,
        value: { error: true },
      })
    } finally {
      this.gaApp.stores.plp.main.setPagination({
        direction,
        value: { pending: false },
      })
    }
  }

  fetchProductsPrev() {
    const pageFirst = this.gaApp.stores.plp.main.getPageNumberFirst

    if (pageFirst <= 1) {
      return false
    }

    this.gaApp.services.filters.main.onPageNumberChange(pageFirst - 1, true)

    return this.fetchProducts(pageFirst - 1, PAGINATION_DIRECTION.PREV)
  }

  fetchProductsNext() {
    const pageLast = this.gaApp.stores.plp.main.getPageNumberLast
    const pagesCount = this.gaApp.stores.plp.main.getPagesCount

    if (pageLast === pagesCount) {
      return false
    }

    this.fetchProducts(pageLast + 1, PAGINATION_DIRECTION.NEXT)
  }

  fetchProductsFirstPage() {
    return this.fetchProducts(1, PAGINATION_DIRECTION.NEXT)
  }

  fetchProductsOnPageIntersect(pageNumber) {
    this.gaApp.services.filters.main.onPageNumberChange(pageNumber, true)

    const pages = this.gaApp.stores.plp.main.pages
    const pagination = this.gaApp.stores.plp.main.pagination

    const nextPage = pages.find((page) => page.number === pageNumber + 1)

    if (pagination.next.pending || pagination.prev.pending || nextPage) {
      return false
    }

    return this.fetchProductsNext()
  }

  /**
   * Считает вес цены и возвращает объект, единственным ключом
   * которого является строка вида «weight-*weight_value*»
   *
   * {
   *  [weight-*weight_value*]: true
   * }
   *
   * @param {object} price объект цены, который приходит с BFF
   * @param {object} priceCurrency объект с инфо о валюте
   * @param {object} pricePrefix объект с инфо о приставке «От»
   *
   * @returns Стилевой модификатор вида weight-*weight_value*
   */
  getPriceSizeModifier(
    price,
    priceCurrency = { show: true },
    pricePrefix = { show: false },
  ) {
    const priceWeight = this.gaApp.services.app.currency.getPriceWeight(
      price.old || price.actual,
    )

    // Если показываем валюту, то учитываем её вес
    const weight = priceCurrency.show
      ? priceWeight?.withCurrency
      : priceWeight?.value

    /**
     * Нужно учитывать вес, когда:
     * - есть обе цены
     * - не показываем префикс «От»
     */
    if (!weight || !price.old || !price.actual || pricePrefix.show) {
      return {}
    }

    // Формируем ключ модификатора вида weight-7 / weight-8-5 / weight-10
    const key = 'weight-' + weight.toString().split('.').join('-')

    return {
      [key]: true,
    }
  }

  get productScrollNavigation() {
    /**
     * Формируем href, затем вызываем метод сервиса app.scrollNavigation,
     * что бы установить якорь в URL
     */
    const setAnchor = ({ itemId, pageNumber }) => {
      const url = new URL(window.location.href)
      url.searchParams.set(FILTER_PAGE_NUMBER.key, pageNumber)

      return this.gaApp.services.app.scrollNavigation.setAnchor(
        itemId,
        decodeURIComponent(url.href),
      )
    }

    /**
     * Записываем в стор якорь взятый из URL
     */
    const saveAndRemoveAnchor = () => {
      const anchor = this.gaApp.services.app.scrollNavigation.getAnchor()

      return this.gaApp.stores.plp.main.setScrollAnchor(anchor)
    }

    /**
     * Забираем якорь из стора, скроллим к элементу и затем удаляем якорь из стора
     */
    const scrollToAnchor = () => {
      const anchor = this.gaApp.stores.plp.main.scrollAnchor

      this.gaApp.services.app.scrollNavigation.scrollToAnchor(anchor)

      this.gaApp.stores.plp.main.setScrollAnchor(null)
    }

    return {
      setAnchor,
      saveAndRemoveAnchor,
      scrollToAnchor,
    }
  }

  resetListingAndFetchProducts() {
    if (this.areHashesEqual()) {
      return false
    }

    this.clearPagesAndFetchFirstPageProducts()
  }

  async hardReset() {
    await this.resetFilters()

    this.clearPagesAndFetchFirstPageProducts()
  }

  /**
   * TODO: порефачить
   *
   * Нужно для того, что бы вызвать перезапрос списка продуктов
   * с флагом очистики страниц(CLEAR_PAGES.AFTER_REQUEST),
   * что бы на странице не был заметен перезапрос продуктов
   */
  async softReset() {
    /**
     * Обновим в URL только фильтр текущего номера страницы (p)
     *
     * Остальные фильтры обновить мы не можем, из-за возможного присутствия utm-меток и других аналитических параметров,
     * которые будут удалены при обновлении фильтров
     */
    if (this.gaApp.services.filters.main.pageNumber.getFromQuery() > 1) {
      this.gaApp.services.filters.main.pageNumber.updateFilter(1)
      this.gaApp.services.filters.main.pageNumber.updateFilterUrl()
    }

    await this.gaApp.services.plp.api.requestFilters()

    return await this.gaApp.services.plp.listing.fetchProducts(
      1,
      PAGINATION_DIRECTION.NEXT,
      CLEAR_PAGES.AFTER_REQUEST,
    )
  }

  onAddressChange() {
    const pages = this.gaApp.stores.plp.main.getPages

    return pages?.length === 1 ? this.softReset() : this.hardReset()
  }

  async resetFilters() {
    const cityId = this.gaApp.services.location.main.getDeliveryAddress()?.id
    const categoryId = this.gaApp.stores.plp.main.getCategoryData?.id

    await this.gaApp.services.filters.main.resetFilters({
      categoryId,
      cityId,
    })

    this.gaApp.stores.filters.main.setFilterMobileValuesOfTrees([])
  }

  clearPagesAndFetchFirstPageProducts() {
    this.gaApp.stores.plp.main.clearPages()

    this.gaApp.services.filters.main.pageNumber.updateFilter(1)

    this.gaApp.services.filters.main.updateFiltersURL()

    this.gaApp.services.plp.listing.fetchProductsFirstPage()
  }

  normalize(products, pageNumber) {
    const includeShortVideos =
      this.gaApp.features.get('plpHowToShortVideoOn') && this.gaApp.isClient

    return products.map((product, index) => {
      product.pageNumber = pageNumber
      // добавляет приоритетный индекс для определения порядка воспроизведения видео
      product.priorityIndex = pageNumber * 100 + index

      if (!product.imageUrls) {
        product.imageUrls = []
      }

      if (includeShortVideos && product.shortVideos?.length) {
        this.gaApp.services.plp.media.setVideoState({ product })
      }

      return product
    })
  }
}
