import { APP_EVENT } from '~/modules/app'
import { PAGE_TYPE } from '~/modules/checkout/constants'
import { AUTH_MODAL_TYPE_NAMES, AUTH_MODAL_TYPES } from '~/modules/modal'

import { ITEM_STATUS } from '../constants'
import { getCartStateInitial } from '../store/main/state'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.subscribeOnLogin()
    this.subscribeOnPageCreated()
    this.subscribeOnThankYouSuccess()
  }

  /**
   * Получение корзины при инициализации страницы
   * если мы на thankYou page - игнорируем, так как там другая логика
   */
  subscribeOnPageCreated() {
    const unsubscribe = this.gaApp.eventBus.subscribe(
      'module/app/router/page-created',
      ({ pageType }) => {
        unsubscribe() // Отписываемся, так как корзину нужно получать только при инициализации

        if (pageType === PAGE_TYPE.THANK_YOU) {
          return
        }

        this.gaApp.services.cart.api.getData()
      },
    )
  }

  /**
   * Получение корзины при успешной инициализации ThankYou страницы
   * Так как заказ оплачен, чистим id гостевой корзины
   */
  subscribeOnThankYouSuccess() {
    this.gaApp.eventBus.subscribe('module/checkout/success', () => {
      this.gaApp.services.cart.guest.removeId()
      this.gaApp.services.cart.api.getData()
    })
  }

  /**
   * Переход на чекаут после успешного логина
   */
  subscribeOnLogin() {
    this.gaApp.eventBus.subscribe(APP_EVENT.LOGIN, async ({ meta }) => {
      if (meta.reason === AUTH_MODAL_TYPES[AUTH_MODAL_TYPE_NAMES.CART]) {
        await this.gaApp.services.cart.api.getData()
        await this.gaApp.services.cart.modal.addProductsToFavoriteBeforeCheckout()
      }
    })
  }

  // get isCacheData() {
  //   return this.gaApp.stores.cart.main.isCacheData
  // }

  get sections() {
    return this.gaApp.stores.cart.main.mapSections
  }

  get wholeStockSku() {
    return this.gaApp.stores.cart.main.ids.wholeStock
  }

  // async fillData() {
  //   if (!this.isCacheData) {
  //     await this.gaApp.services.cart.api.getData()
  //   }
  // }

  getItems() {
    return this.gaApp.stores.cart.main.items
  }

  getItem(id) {
    return (
      this.gaApp.stores.cart.main.items.find((item) => item.id === id) || null
    )
  }

  getProductItem(sku) {
    return this.gaApp.stores.cart.main.itemsHashTable?.[sku] ?? null
  }

  getProductId(sku) {
    return this.getProductItem(sku)?.id
  }

  getSectionIds(sectionName) {
    return (
      this.gaApp.stores.cart.main.mapSections
        .get(sectionName)
        ?.map(({ id }) => id) ?? []
    )
  }

  getSectionSkus(sectionName) {
    return (
      this.gaApp.stores.cart.main.mapSections
        .get(sectionName)
        ?.map(({ sku }) => sku) ?? []
    )
  }

  getItemsIds() {
    return this.gaApp.stores.cart.main.items.map(({ id }) => id)
  }

  checkProductWholeStock(sku) {
    return (
      this.gaApp.stores.cart.main.itemsHashTable?.[sku]?.canAddMoreFromPDP ===
      false
    )
  }

  checkProductError(sku) {
    return this.gaApp.stores.cart.main.itemsHashTable?.[sku]?.errors?.length > 0
  }

  getItemStatus(item) {
    switch (true) {
      case item.view === 'outofstock':
        return ITEM_STATUS.OUT_OF_STOCK

      case !item.canAddMore:
        return ITEM_STATUS.CANT_ADD_MORE

      case item.stockExceeded:
        return ITEM_STATUS.MORE_THAN_IN_STOCK

      default:
        return ITEM_STATUS.OUT_OF_STOCK
    }
  }

  getItemsStatuses() {
    return this.gaApp.stores.cart.main.items.map((item) => ({
      id: item.id,
      qty: item.qty,
      itemPrice: item.price?.actual,
      rowPrice: item.price?.row,
      stockFlag: this.getItemStatus(item),
    }))
  }

  setCart(cart) {
    this.gaApp.stores.cart.main.$patch(cart)

    this.gaApp.services.cart.modal.updateCouponData()
    this.gaApp.services.cart.price.setTotalsWeight()
  }

  setCoupon(value) {
    this.gaApp.stores.cart.main.totals.coupon = value
  }

  setAvailableCoupons(coupons) {
    this.gaApp.stores.cart.main.totals.availableCoupons = coupons
  }

  setCache(cart) {
    this.setCart(cart)
    this.gaApp.stores.cart.main.isCacheData = true
  }

  resetState() {
    this.setCart(getCartStateInitial())
  }

  // Метод для перехода на чекаут с проверкой корзины
  async toCheckoutWithChecking() {
    await this.gaApp.services.cart.api.getData()

    if (this.gaApp.stores.cart.main.validForOrder) {
      this.gaApp.services.cart.modal.redirectToCheckout()
      await new Promise(() => {}) // Ожидание пока происходит переход на чекаут
    }
  }
}
