import { ENTITIES, REGEXPS } from '@ga/constants/text'

const isPlusSign = (word) => REGEXPS.PLUS_SIGN.test(word)
const isSlashSign = (word) => REGEXPS.SLASH_SIGN.test(word)
const hasProperLength = (word, maxWordLength) => word.length < maxWordLength

// Добавляет неразрывный пробел к слову
const addNonBreakingSpace = (word) => {
  return word + ENTITIES.NON_BREAKING_SPACE
}

// Добавляет обычный пробел к слову
const addRegularSpace = (word) => {
  return word + ENTITIES.SPACE
}

// Определяет, какой пробел нужно добавить в зависимости от следующего слова
const determineSpaceToAdd = (word, nextWord, maxWordLength) => {
  if (!nextWord) {
    return word
  }

  if (
    hasProperLength(word, maxWordLength) ||
    isPlusSign(nextWord) ||
    isSlashSign(nextWord)
  ) {
    return addNonBreakingSpace(word)
  }

  return addRegularSpace(word)
}

// Преобразует текст, добавляя соответствующие неразрывные пробелы
const addNonBreakingSpaces = (text, maxWordLength) => {
  const words = text.split(' ')

  const transformedText = words
    .map((word, index, words) =>
      determineSpaceToAdd(word, words[index + 1], maxWordLength),
    )
    .join('')

  return transformedText
}

export { addNonBreakingSpaces }
