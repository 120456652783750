import debounce from 'lodash/debounce'

import { set } from 'vue'

import { FILTER_KEY, SEARCH_DEBOUNCE_DELAY } from '../../constant'

export class CitiesService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.updateCitiesFilter = debounce(
      this.updateCitiesFilter,
      SEARCH_DEBOUNCE_DELAY,
    )
  }

  /**
   * Ищёт фильтр по городу и устанавливает его index в стор в массиве фильтров
   *
   * @param {Array} list - список фильтров
   */
  setFilterCityIndex(list = []) {
    this.gaApp.stores.filters.cities.cityFilterIndex = list.findIndex(
      ({ key }) => key === FILTER_KEY.CITY_ID,
    )
  }

  // TODO оптимизировать. Сохранять в стор город которого нет в списке с бэка, и использовать в проверке на запрос что он уже существует
  /**
   * Обновляет subtitle в фильтрах города по id города, если его нет в дефолтном списке городов пришедших с бэка
   *
   * @param {Number} cityId - id города
   * @param {Array} list - список фильтров
   * @returns {Array} - возвращает модифицированный массив со всеми фильтрами
   */
  async updateFilterCitySubtitle(cityId, list) {
    if (!cityId) return list

    this.setFilterCityIndex(list)
    const cityIndex = this.gaApp.stores.filters.cities.cityFilterIndex

    const isExist = list[cityIndex]?.values.some(({ id }) => id === cityId)

    // Если текущего города нет в списке с бэка, то делаем запрос на получение его названия
    if (!isExist) {
      try {
        const { location } =
          await this.gaApp.services.filters.apiCities.geocodeCities(cityId)

        return list.map((item, index) => {
          if (index === cityIndex) {
            return {
              ...item,
              subtitle: location.city,
            }
          }

          return item
        })
      } catch (error) {
        console.error(error)
      }
    }

    return list
  }

  setFilterCitiesDefault() {
    const index = this.gaApp.stores.filters.cities.cityFilterIndex

    this.gaApp.stores.filters.cities.citiesFilterDefault =
      this.gaApp.stores.filters.main.filters[index].values
  }

  updateCitiesFilter(query) {
    if (!query.trim()) {
      this.setFilterCities(this.gaApp.stores.filters.cities.citiesFilterDefault)
      return
    }

    this.getCities(query)
  }

  async getCities(query) {
    try {
      const list =
        await this.gaApp.services.filters.apiCities.suggestCities(query)

      this.setCitiesToFilterFormat(list)
    } catch (error) {
      console.error(error)
    }
  }

  setCitiesToFilterFormat(list) {
    const data = list?.map(({ value, text }) => ({
      id: value,
      name: text,
      isEnabled: true,
    }))

    this.setFilterCities(data)
  }

  setFilterCities(list) {
    const index = this.gaApp.stores.filters.cities.cityFilterIndex

    set(this.gaApp.stores.filters.main.filters, index, {
      ...this.gaApp.stores.filters.main.filters[index],
      values: list,
    })
  }
}
