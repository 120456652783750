import get from 'lodash/get'

import { MAPS } from '../../../constants/digital'
import {
  ChosePayMethodBuilder,
  ClickBuyBuilder,
  ClickGoToPurchasesReceiveReturnBuilder,
  ClickPayBuilder,
  ClickReceiveCardBuilder,
  CloseModalBuilder,
  GenerateDesignButtonClickBuilder,
  GenerateMoreDesignButtonClickBuilder,
  OpenPageReceiveCardBuilder,
  PauseVideoBuilder,
  PayErrorBuilder,
  PlayVideoBuilder,
  PromptChipClickBuilder,
  ReactionReceiveBuilder,
  ReactionSelectModalCloseBuilder,
  ReactionSentBuilder,
  ReactionsSelectModalOpenBuilder,
  SelectDesignBuilder,
  SelectDesignTypeBuilder,
  SelectPriceBuilder,
  SelectTimeBuilder,
  SelectWhomBuilder,
  StartGenerateButtonClickBuilder,
  StartVideoBuilder,
  SuccessClearanceBuilder,
} from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class GiftCardsAnalytics extends BaseController {
  constructor(gaApp, core) {
    super(gaApp, core)

    this.init()
  }

  /**
   * Подписка на события EventBus
   */
  init() {
    const unsubscribe = this.gaApp.eventBus.subscribe(
      'layer/analitycs/initDataLayer',
      () => {
        this.onOpenPageReceiveReturnCard()

        unsubscribe()
      },
    )
  }

  /**
   * Начало оформления.
   * Клик по кнопке "Купить"
   */
  onClickBuy(cardType = 'digital') {
    this.send(ClickBuyBuilder, cardType)
  }

  /**
   * Выбор дизайна.
   * Клик по кнопке "Далее"
   */
  onSelectDesign() {
    this.send(SelectDesignBuilder, {
      imageId: get(this.gaApp.stores.giftCards.digital, MAPS.IMG),
      backgroundId: get(this.gaApp.stores.giftCards.digital, MAPS.COLOR),
      designType: this.gaApp.stores.giftCards.digital.selectedDesignType,
    })
  }

  /**
   * Выбор номинала.
   * Клик по кнопке "Далее"
   */
  onSelectPrice(actionType) {
    this.send(SelectPriceBuilder, {
      actionType,
      customPriceValue: Number(
        get(this.gaApp.stores.giftCards.digital, MAPS.AMOUNT),
      ),
      customCurrency: this.gaApp.stores.giftCards.digital.priceInfo.currency,
    })
  }

  /**
   * Выбор адресата.
   * Клик по кнопке "Далее"
   */
  onSelectWhom() {
    const isSelf = this.gaApp.stores.giftCards.digital.isUserRecipient

    this.send(SelectWhomBuilder, {
      recipient: isSelf ? 'myself' : 'friend',
    })
  }

  /**
   * Выбор времени отправки.
   * Клик по кнопке "Далее"
   */
  onSelectTime() {
    this.send(SelectTimeBuilder, {
      selectedDeliveryTime: this.getSelectedDeliveryTime(),
      selectedTimeZone: this.getSelectedTimeZone(),
    })
  }

  /**
   * Оплата.
   * Клик по кнопке "Оплатить"
   */
  onClickPay() {
    this.send(ClickPayBuilder, {
      customPriceValue: Number(
        get(this.gaApp.stores.giftCards.digital, MAPS.AMOUNT),
      ),
      customCurrency: this.gaApp.stores.giftCards.digital.priceInfo.currency,
    })
  }

  /**
   * Закрытие окна оформления.
   * Клик по крестику закрытия
   */
  onCloseModal() {
    this.send(CloseModalBuilder, {
      eventStep: Number(this.gaApp.stores.giftCards.digital.currentStep) + 1,
    })
  }

  /**
   * Ошибка во время оплаты заказа
   * @param {string} errorMessage текст сообщения об ошибке
   */
  onPayError(errorMessage) {
    this.send(PayErrorBuilder, { errorMessage })
  }

  /**
   * Успешное оформление.
   * Открытие страницы "Заказ оформлен"
   */
  onSuccessClearance() {
    const { orderId, paymentType } = this.gaApp?.route?.query

    this.send(SuccessClearanceBuilder, { orderId, paymentType })
  }

  /**
   * Получение карты.
   * Открытие страницы "Получение карты"
   */
  onOpenPageReceiveCard() {
    const type = this.getAnalyticsOrderType()

    this.send(OpenPageReceiveCardBuilder, {
      type,
      sourceLink: this.gaApp.services.giftCards.digitalReceive.getSourceLink(),
    })
  }

  /**
   * Получение карты
   * Клик по кнопке открытия поздравления.
   */
  onClickReceiveCard() {
    this.send(ClickReceiveCardBuilder)
  }

  /**
   * Получение карты
   * Открытие окна просмотра видео.
   */
  onStartVideo() {
    this.send(StartVideoBuilder)
  }

  /**
   * Получение карты
   * Начало просмотра видео.
   */
  onPlayVideo() {
    this.send(PlayVideoBuilder)
  }

  /**
   * Получение карты
   * Конец просмотра видео.
   */
  onPauseVideo() {
    this.send(PauseVideoBuilder)
  }

  /**
   * Получение возвратной карты
   * Открытие страницы ВЭПК
   */

  onOpenPageReceiveReturnCard() {
    const isGiftCardsReceiveReturnRoute = this.gaApp.route.fullPath.includes(
      'cards/receive-return',
    )
    if (!isGiftCardsReceiveReturnRoute) return

    const sourceLink = this.gaApp?.route?.query?.from
    sourceLink &&
      window.dataLayer.push({
        source_link: sourceLink,
      })
  }

  /**
   * Получение возвратной карты
   * Клик по кнопке "Перейти к покупкам" на странице ВЭПК
   */

  onGoToPurchasesClick() {
    this.send(ClickGoToPurchasesReceiveReturnBuilder)
  }

  /**
   * Отображение модального окна с реакциями
   *
   * @param {'click_button' | 'timer'} showType тип триггера отображения окна с реакциями. Возможные значения:
   * - click_button – клик на кнопку "перейти к покупкам"
   * - timer – отображение по таймеру
   */
  onReactionsSelectModalOpen(triggerType) {
    const type = this.getAnalyticsOrderType()

    this.send(ReactionsSelectModalOpenBuilder, { triggerType, type })
  }

  /**
   * Получатель не оставил реакцию и закрыл модальное окно
   */
  onReactionSelectModalClose() {
    const type = this.getAnalyticsOrderType()

    this.send(ReactionSelectModalCloseBuilder, type)
  }

  /**
   * Получатель оставил реакцию
   */
  onReactionSent() {
    const type = this.getAnalyticsOrderType()

    this.send(ReactionSentBuilder, type)
  }

  /**
   * Показ страницы с отправленной реакцией
   */
  onReactionReceive() {
    this.send(ReactionReceiveBuilder)
  }

  /**
   * Пользователь начал оплату с выбранным способом оплаты
   * @param {import('../../../constants/payment').PAY_METHOD} payMethod способ оплаты заказа
   */
  onChosePayMethod(payMethod) {
    this.send(ChosePayMethodBuilder, { payMethod })
  }

  /**
   * Выбор типа дизайна ЭПК
   * @param {import('../../../constants/digital').DESIGN_TYPES} designType тип дизайна ЭПК
   */
  onSelectDesignType(designType) {
    this.send(SelectDesignTypeBuilder, { type: designType })
  }

  /**
   * Открытие модального окна ввода запроса для нейросети, когда не было сгенерировано дизайнов ранее
   */
  onGenerateDesignButtonClick() {
    this.send(GenerateDesignButtonClickBuilder)
  }

  /**
   * Выбор подсказки при вводе запроса нейросети
   * @param {string} chipText текст подсказки
   */
  onPromptChipClick(chipText) {
    this.send(PromptChipClickBuilder, { text: chipText })
  }

  /**
   * Начало генерации дизайна
   * @param {boolean} isHintsUsed при формировании запроса использованы баблы
   * @param {boolean} isTextTyped при формировании текст был напечатан с клавиатуры
   */
  onStartGenerateButtonClick(isHintsUsed, isTextTyped) {
    this.send(StartGenerateButtonClickBuilder, { isHintsUsed, isTextTyped })
  }

  /**
   * Открытие модального окна ввода запроса нейросети, если дизайны были сгенерированы ранее
   * @param {boolean} succeedGeneration превышен ли лимит
   */
  onGenerateMoreDesignButtonClick(succeedGeneration) {
    this.send(GenerateMoreDesignButtonClickBuilder, { succeedGeneration })
  }
}
