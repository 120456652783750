<template>
  <ga-module-app>
    <ga-noscript v-if="!isWebview" />

    <ga-header
      v-if="isHeaderVisibleOnce && !isWebview"
      :style="!isHeaderVisible && styleHidden"
    />

    <template v-if="defer(2)">
      <ga-portal-layout-prepend />

      <nuxt :style="!isMainVisible && styleHidden" />
    </template>

    <template v-if="defer(10)">
      <ga-module-footer
        v-if="isFooterVisibleOnce && !isWebview"
        :style="
          (!isFooterVisible && styleHidden) ||
          (isFooterTransparent && styleTransparent)
        "
      />
      <ga-mobile-app-schema v-if="!isWebview" />
    </template>

    <ga-module-ticker v-if="defer(10) && !isWebview" />

    <client-only>
      <template v-if="defer(5)">
        <ga-portal-target multiple />
        <ga-portal-target name="modal-search" slim />
        <ga-portal-target name="modal-stories" slim />
        <ga-portal-target name="modal-story-products" slim />
        <ga-portal-target name="modal-gift-order" slim />
        <ga-portal-target name="modal-gift-menu" slim />
        <ga-portal-target name="modal-gift-receipt" slim />
        <ga-portal-target name="modal-gift-receive-1" slim />
        <ga-portal-target name="modal-gift-receive-2" slim />
        <ga-portal-target name="modal-gift-receive-qr" slim />
        <ga-portal-target name="modal-location-confirm-address" slim />
        <ga-portal-target name="modal-location-modal" slim />
        <ga-portal-target name="modal-location-specify-select" slim />
        <ga-portal-target name="modal-location-specify-edit" slim />
        <ga-portal-target name="modal-contacts" slim />
        <ga-portal-target name="modal-page" slim />
        <ga-portal-target name="modal-pdp-gallery" slim />
        <ga-portal-target name="modal-pdp-delivery-info" slim />
        <ga-portal-target name="modal-pdp-offline-1" slim />
        <ga-portal-target name="modal-pdp-offline-2" slim />
        <ga-portal-target name="modal-review-product" slim />
        <ga-portal-target name="modal-plp-filter" slim />
        <ga-portal-target name="modal-brandzone-navigation" slim />
        <ga-portal-target name="modal-customer-code" slim />
        <ga-portal-target name="modal-customer-order-cancel" slim />
        <ga-portal-target name="modal-customer-address-edit" slim />
        <ga-portal-target name="modal-customer-address-delete" slim />
        <ga-portal-target name="modal-customer-address-info" slim />
        <ga-portal-target name="modal-pickup-point" slim />
        <ga-portal-target name="modal-subscription" slim />
        <ga-portal-target name="modal-gift-cards-b2b-cover-selector" />
        <ga-portal-target name="modal-gift-cards-b2b-felicitation-preview" />
        <ga-portal-target name="modal-gift-cards-b2b-success-modal" />
        <ga-portal-target name="modal-gift-cards-b2b-landing-menu" />
        <ga-portal-target name="modal-gift-cards-b2b-landing-cards" />
        <ga-portal-target name="modal-gift-cards-b2b-city-selector" slim />
        <ga-portal-target name="modal-gift-cards-b2b-delivery-address" slim />
        <ga-portal-target name="modal-gift-cards-b2b-pickup-store" slim />
        <ga-portal-target name="modal-gift-cards-payment" />
        <ga-portal-target name="modal-gift-cards-reactions" />
        <ga-portal-target name="modal-orders-ondemand" slim />
        <ga-portal-target name="modal-promo-availability" slim />
        <ga-portal-target name="modal-customer-loyalty-info" slim />
        <ga-portal-target name="modal-customer-support-edit" slim />
        <ga-portal-target name="modal-customer-loyalty-details" slim />
        <ga-portal-target name="modal-customer-change-delivery" slim />
        <ga-portal-target name="modal-customer-loyalty-qr" slim />
        <ga-portal-target name="modal-gift-xmas" slim />
        <ga-portal-target name="modal-promo-xmas" slim />
        <ga-portal-target name="modal-quiz-xmas" slim />
        <ga-portal-target name="modal-welcome-puig" slim />
        <ga-portal-target name="modal-customer-notification" slim />

        <ga-notifications />

        <ga-viewport-excluded-header v-if="!isWebview" />

        <!-- Без запроса за футером падает -->
        <!-- <ga-contacts-modal /> -->
        <template v-if="shouldAcceptCookiesPermission && !isWebview">
          <ga-module-app-cookie-panel v-if="shouldRenderCookiePanel" />
          <ga-module-app-cookie-modal v-if="shouldRenderCookieModal" />
          <ga-module-app-cookie-settings-modal />
        </template>

        <ga-module-app-cookie-notification-panel
          v-if="shouldRenderCookieNotificationPanel && !isWebview"
        />

        <ga-module-app-lang-switcher-modal
          v-if="showLangSwitcher && !isWebview"
        />

        <ga-module-app-country-switcher-modal
          v-if="storeSwitcherFeatureOn && !isWebview"
        />

        <ga-module-app-country-confirm-modal
          v-if="storeSwitcherFeatureOn && !isWebview"
        />

        <ga-module-debug-panel-modal v-if="isDebugMode && !isWebview" />

        <ga-module-adv-modal v-if="showAdvModal && !isWebview" type="adv-app" />

        <ga-module-super-modal />

        <template v-if="!isWebview">
          <ga-module-location-modal />
          <ga-module-location-specify-edit-modal />
          <ga-module-location-specify-select-modal />

          <ga-module-cart-share-modal />
          <ga-module-cart-warning-modal />
          <ga-module-support-modal />
          <ga-module-review-products-modal />
          <ga-module-customer-notification-modal />
        </template>

        <ga-module-articles-modal-gallery v-if="!isWebview" />
      </template>
    </client-only>
  </ga-module-app>
</template>

<script>
import { useDeferRender } from '@ga/use/defer-render'

import { GaNoscript } from '@ga/ui-components/noscript'
import { GaPortalTarget } from '@ga/ui-components/portal-target'

import { GaMobileAppSchema } from '~/modules/app/components'
import { GaHeader } from '~/modules/header/components'
import { GaNotifications } from '~/modules/notifications/components'

import { GaPortalLayoutPrepend } from '~/components/portal-layout-prepend'
import { GaViewportExcludedHeader } from '~/components/viewport-excluded-header'

export default {
  name: 'layout-default',

  components: {
    GaHeader,
    GaMobileAppSchema,

    GaNoscript,

    GaPortalTarget,

    GaNotifications,
    GaPortalLayoutPrepend,
    GaViewportExcludedHeader,
  },

  // TODO: удалить после того, как переведём весь сайт на Vue
  // eslint-disable-next-line max-statements, complexity
  middleware: 'layout',

  setup() {
    const { defer } = useDeferRender()

    return {
      defer,
    }
  },
  data() {
    return {
      isHeaderVisibleOnce: false,
      isFooterVisibleOnce: false,
    }
  },

  computed: {
    isWebview() {
      return this.$gaApp.isWebview
    },

    styleHidden() {
      return {
        height: 0,
        minHeight: 0,
        overflow: 'hidden',
        padding: 0,
      }
    },

    styleTransparent() {
      return {
        opacity: 0,
      }
    },

    isFooterVisible() {
      return this.$gaApp.stores.app.main.footerVisible
    },

    isFooterTransparent() {
      return this.$gaApp.stores.app.main.footerTransparent
    },

    isHeaderVisible() {
      return this.$gaApp.stores.app.main.headerVisible
    },

    isMainVisible() {
      return this.$gaApp.stores.app.main.mainVisible
    },

    isMobileAppBannerVisible() {
      return this.$gaApp.stores.app.main.mobileAppBannerVisible
    },

    noTransitionStyle() {
      if (!this.$gaApp.stores.app.main.routeChange) {
        return ''
      }

      return `* {
              transition: none !important;
          }`
    },

    browserScrollTop() {
      return this.$gaApp.stores.app.main.getScrollTop()
    },

    shouldAcceptCookiesPermission() {
      return this.$gaApp.features.get('modalCookiesPermission')
    },

    shouldRenderCookieNotificationPanel() {
      return (
        !this.$gaApp.stores.app.cookie.notification.isAnswered &&
        this.$gaApp.features.get('сookiesNotificationPanel')
      )
    },

    shouldRenderCookieModal() {
      return this.$gaApp.stores.app.cookie.permission.shouldRenderCookieModal
    },
    shouldRenderCookiePanel() {
      return this.$gaApp.stores.app.cookie.permission.shouldRenderCookiePanel
    },
    isDebugMode() {
      return this.$gaApp.stores.debugPanel.main.debugMode
    },

    showLangSwitcher() {
      return this.$gaApp.services.app.langSwitcher.hasAvailableLanguages
    },

    storeSwitcherFeatureOn() {
      return this.$gaApp.features.get('storeSwitcher')
    },

    showAdvModal() {
      return this.$gaApp.stores.modal.advModal.shouldRender
    },
  },

  watch: {
    isHeaderVisible(value) {
      if (value) {
        this.isHeaderVisibleOnce = true
      }
    },
    isFooterVisible(value) {
      if (value) {
        this.isFooterVisibleOnce = true
      }
    },
  },

  created() {
    if (this.$gaApp.isServer) {
      this.isHeaderVisibleOnce = true
      this.isFooterVisibleOnce = true
    }
  },

  methods: {
    onMobileAppBannerClose() {
      this.$services.notification.banner.hideMobileAppBanner()
    },
  },

  // eslint-disable-next-line vue/order-in-components
  head() {
    /**
     * Возвращает MetaInfo
     * addSeoAttributes — Добавляет различные атрибуты SEO.
     * https://i18n.nuxtjs.org/api#nuxti18nhead
     */
    const i18nSeo = this.$nuxtI18nHead({ addSeoAttributes: true })

    const siteName = this.$t('layouts.default.meta.siteName')

    return {
      htmlAttrs: {
        ...i18nSeo.htmlAttrs,

        prefix: 'og: https://ogp.me/ns#',
        translate: 'no',
      },
      meta: [
        ...i18nSeo.meta,

        {
          hid: 'robots',
          name: 'robots',
          content: 'INDEX,FOLLOW',
        },

        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: siteName,
        },

        {
          name: 'theme-color',
          content: '#ffffff',
        },
        {
          name: 'msapplication-TileImage',
          content: '/_static/favicons/mstile-144x144.png',
        },
        {
          name: 'msapplication-config',
          content: '/_static/scripts/browser-updater/browserconfig.xml',
        },

        // twitter - телеграм берет теги твиттера
        {
          hid: 'twitter:image:width',
          name: 'twitter:image:width',
          content: 600,
        },
        {
          hid: 'twitter:image:height',
          name: 'twitter:image:height',
          content: 435,
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image',
        },
      ],
      link: [
        ...i18nSeo.link,

        // TODO: посмотреть на показатели CLS и оценить проблемы,
        // связанные с приоретизацией запросов
        {
          rel: 'preload',
          as: 'font',
          type: 'font/woff2',
          href: '/_static/fonts/GraphikLCG-Regular.woff2',
          crossorigin: 'anonymous',
        },
        {
          rel: 'preload',
          as: 'font',
          type: 'font/woff2',
          href: '/_static/fonts/GraphikLCG-Medium.woff2',
          crossorigin: 'anonymous',
        },
        // {
        //   rel: 'preload',
        //   as: 'font',
        //   type: 'font/woff2',
        //   href: '/_static/fonts/GraphikLCG-Regular.woff2',
        //   crossorigin: 'anonymous',
        //   fetchpriority: 'high',
        // },
        // {
        //   rel: 'preload',
        //   as: 'font',
        //   type: 'font/woff2',
        //   href: '/_static/fonts/GraphikLCG-RegularItalic.woff2',
        //   crossorigin: 'anonymous',
        //   fetchpriority: 'high',
        // },
        // {
        //   rel: 'preload',
        //   as: 'font',
        //   type: 'font/woff2',
        //   href: '/_static/fonts/GraphikLCG-Medium.woff2',
        //   crossorigin: 'anonymous',
        //   fetchpriority: 'high',
        // },
        // {
        //   rel: 'preload',
        //   as: 'font',
        //   type: 'font/woff2',
        //   href: '/_static/fonts/GraphikLCG-MediumItalic.woff2',
        //   crossorigin: 'anonymous',
        //   fetchpriority: 'high',
        // },
        // {
        //   rel: 'preload',
        //   as: 'font',
        //   type: 'font/woff2',
        //   href: '/_static/fonts/DrukWide-Bold.woff2',
        //   crossorigin: 'anonymous',
        //   fetchpriority: 'high',
        // },
        // {
        //   rel: 'preload',
        //   as: 'font',
        //   type: 'font/woff2',
        //   href: '/_static/fonts/DrukText-Medium.woff2',
        //   crossorigin: 'anonymous',
        //   fetchpriority: 'high',
        // },
        // {
        //   rel: 'preload',
        //   as: 'font',
        //   type: 'font/woff2',
        //   href: '/_static/fonts/DrukCy-Medium.woff2',
        //   crossorigin: 'anonymous',
        //   fetchpriority: 'high',
        // },
        // {
        //   rel: 'preload',
        //   as: 'font',
        //   type: 'font/woff2',
        //   href: '/_static/fonts/NeueMontreal-Regular.woff2',
        //   crossorigin: 'anonymous',
        //   fetchpriority: 'high',
        // },
        // {
        //   rel: 'preload',
        //   as: 'font',
        //   type: 'font/woff2',
        //   href: '/_static/fonts/NeueMontreal-Bold.woff2',
        //   crossorigin: 'anonymous',
        //   fetchpriority: 'high',
        // },
        // {
        //   rel: 'preload',
        //   as: 'font',
        //   type: 'font/woff2',
        //   href: '/_static/fonts/NeueMontreal-SemiBold.woff2',
        //   crossorigin: 'anonymous',
        //   fetchpriority: 'high',
        // },
        // {
        //   rel: 'preload',
        //   as: 'font',
        //   type: 'font/woff2',
        //   href: '/_static/fonts/NeueMontreal-Medium.woff2',
        //   crossorigin: 'anonymous',
        //   fetchpriority: 'high',
        // },
        // {
        //   rel: 'preload',
        //   as: 'font',
        //   type: 'font/woff2',
        //   href: '/_static/fonts/NeueMontreal-Thin.woff2',
        //   crossorigin: 'anonymous',
        //   fetchpriority: 'high',
        // },
        {
          rel: 'mask-icon',
          href: '/_static/favicons/safari-pinned-tab.svg',
          color: '#000',
        },
        {
          rel: 'apple-touch-icon',
          href: '/_static/favicons/apple-touch-icon.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          href: '/_static/favicons/apple-touch-icon-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '57x57',
          href: '/_static/favicons/apple-touch-icon-57x57.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '57x57',
          href: '/_static/favicons/apple-touch-icon-57x57-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '60x60',
          href: '/_static/favicons/apple-touch-icon-60x60.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '60x60',
          href: '/_static/favicons/apple-touch-icon-60x60-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '72x72',
          href: '/_static/favicons/apple-touch-icon-72x72.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '72x72',
          href: '/_static/favicons/apple-touch-icon-72x72-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '76x76',
          href: '/_static/favicons/apple-touch-icon-76x76.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '76x76',
          href: '/_static/favicons/apple-touch-icon-76x76-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '114x114',
          href: '/_static/favicons/apple-touch-icon-114x114.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '114x114',
          href: '/_static/favicons/apple-touch-icon-114x114-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '120x120',
          href: '/_static/favicons/apple-touch-icon-120x120.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '120x120',
          href: '/_static/favicons/apple-touch-icon-120x120-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '144x144',
          href: '/_static/favicons/apple-touch-icon-144x144.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '144x144',
          href: '/_static/favicons/apple-touch-icon-144x144-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '152x152',
          href: '/_static/favicons/apple-touch-icon-152x152.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '152x152',
          href: '/_static/favicons/apple-touch-icon-152x152-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '167x167',
          href: '/_static/favicons/apple-touch-icon-167x167.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '167x167',
          href: '/_static/favicons/apple-touch-icon-167x167-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/_static/favicons/apple-touch-icon-180x180.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '180x180',
          href: '/_static/favicons/apple-touch-icon-180x180-precomposed.png',
        },

        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/_static/favicons/favicon-16x16.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/_static/favicons/favicon-32x32.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '194x194',
          href: '/_static/favicons/favicon-194x194.png',
        },

        {
          rel: 'shortcut icon',
          type: 'image/ico',
          href: '/_static/favicons/favicon.ico',
        },
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: '/_static/favicons/favicon.ico',
        },

        {
          rel: 'manifest',
          href: '/_static/site.webmanifest',
        },
      ],
      noscript: [
        {
          innerHTML:
            '<style>.ga-noscript { display: block !important }</style>',
        },
      ],
      style: [{ cssText: this.noTransitionStyle, type: 'text/css' }],
      __dangerouslyDisableSanitizers: ['noscript'],
    }
  },
}
</script>
