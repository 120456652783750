import { encode } from 'js-base64'

import { APP_EVENT } from '~/modules/app'
import {
  AUTH_MODAL_REASON_TO_OPEN,
  AUTH_MODAL_TYPE_NAMES,
  AUTH_MODAL_TYPES,
} from '~/modules/modal'

import { GRANT_TYPE } from '../constants'
import { buildContentTypeHeader, buildRequestPayload } from '../helpers'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get isAuthorized() {
    return this.gaApp.stores.user.main.isAuthorized
  }

  /**
   * очищаем данные авторизации в local storage
   */
  removeAuthLocalStorageData() {
    this.gaApp.services.auth.localStorage.removeLocalStorageAuthModalOpenReason()
    this.gaApp.services.auth.localStorage.removeLocalStorageAuthRedirectUrl()
  }

  /**
   * Метод запроса СМС-кода по номеру телефона
   *
   * @param {object} obj
   * @param {string} obj.phone - Номер телефона, на который отправить СМС
   * @param {string} obj.phoneCode - Код страны, например 'ru'
   * @param {string} obj.captchaToken - каптча-токен
   *
   * @returns {object} response | error
   */
  async sendAuthCode({ phone, phoneCode, captchaToken } = {}) {
    try {
      const reqType = 'json'
      const data = buildRequestPayload('sendCode', reqType, {
        phone,
        phoneCode,
        captchaToken,
        magento: !this.gaApp.features.get('cartPlaidApi'), // при включенной плед корзине, отправляем флаг для отключения мага авторизации
      })
      const config = buildContentTypeHeader(reqType)

      const response = await this.gaApp.services.auth.api.sendCodeV2(
        data,
        config,
      )

      if (response.data) {
        this.gaApp.stores.auth.main.setResendCodeToken(
          response.data.resend_token,
        )

        this.gaApp.stores.auth.main.setTokenOptions({
          grantType: response.data.grant_type,
        })
      }

      return response
    } catch (error) {
      this.gaApp.stores.auth.main.setResendCodeToken(null)

      throw error
    }
  }

  /**
   * Метод повторного запроса СМС-кода
   *
   * @param {object} obj
   * @param {string} obj.phone - Номер телефона, на который отправить СМС
   * @param {string} obj.phoneCode - Код страны, например 'ru'
   * @param {string} obj.captchaToken - каптча-токен
   *
   * @returns {object} response | error
   */
  async resendAuthCode({ phone, phoneCode, captchaToken } = {}) {
    try {
      const resendToken = this.gaApp.stores.auth.main.resendCodeToken

      const reqType = 'json'
      const data = buildRequestPayload('resendCode', reqType, {
        phone,
        phoneCode,
        resendToken,
        captchaToken,
        magento: !this.gaApp.features.get('cartPlaidApi'), // при включенной плед корзине, отправляем флаг для отключения мага авторизации
      })
      const config = buildContentTypeHeader(reqType)

      const response = await this.gaApp.services.auth.api.resendCodeV2(
        data,
        config,
      )

      if (response.data) {
        this.gaApp.stores.auth.main.setResendCodeToken(
          response.data.resend_token,
        )

        this.gaApp.stores.auth.main.setTokenOptions({
          grantType: response.data.grant_type,
        })
      }

      return response
    } catch (error) {
      this.gaApp.stores.auth.main.setResendCodeToken(null)

      throw error
    }
  }

  /**
   * Метод подтверждения СМС-кода
   *
   * @param {object} obj
   * @param {string} obj.code - код из СМС-сообщения
   * @param {string} obj.phone - Номер телефона, куда была отправлен СМС-код
   *
   * @returns {object} response | error
   */
  async login({ code, phone, meta }) {
    const reqType = 'form'

    const grantType = this.gaApp.stores.auth.main.grantType
    const data = buildRequestPayload('loginGrandType', reqType, {
      code,
      phone,
      grantType,
      magento: !this.gaApp.features.get('cartPlaidApi'), // при включенной плед корзине, отправляем флаг для отключения мага авторизации
    })

    const config = buildContentTypeHeader(reqType)

    const response = await this.gaApp.services.auth.api.login(data, config)

    if (response.data) {
      // eslint-disable-next-line camelcase
      const { magento_data = {} } = response.data

      // Запрашиваем данные юзера и устанавливаем
      await this.gaApp.services.user.api.fetchUserInfoFull()

      // Устанавливаем для magento данные в localStorage
      // eslint-disable-next-line camelcase
      this.gaApp.services.cache.main.saveUser(magento_data.customer_section)

      // Сбрасываем токен для перезапроса смс-кода
      this.gaApp.stores.auth.main.setResendCodeToken(null)
    }

    this.gaApp.eventBus.publish(APP_EVENT.LOGIN, {
      meta,
    })

    return response
  }

  /**
   * готовит метаданные для обработки события логина
   */
  prepareModalMeta() {
    const reason =
      this.gaApp.services.auth.localStorage.getLocalStorageAuthModalOpenReason()

    return {
      meta: {
        reason,
      },
    }
  }

  /**
   * формирует и публикует событие логина
   */
  publishLoginEvent(modalMeta) {
    this.gaApp.eventBus.publish(APP_EVENT.LOGIN, modalMeta)
  }

  /**
   * публикуем событие логина, очищаем данные local storage
   */
  publishLoginEventWithoutRedirect(modalMeta) {
    this.publishLoginEvent(modalMeta)
    this.gaApp.services.auth.main.removeAuthLocalStorageData()
  }

  /**
   * редиректим на страницу, с которой уходили на внешний сервис авторизации,
   * после этого публикуем событие логина, очищаем данные local storage
   */
  publishLoginEventWithRedirect(modalMeta) {
    const path =
      this.gaApp.services.auth.localStorage.getLocalStorageAuthRedirectUrl() ??
      '/'

    this.gaApp.services.app.router.push(path, () => {
      this.publishLoginEvent(modalMeta)
    })
  }

  /**
   * в зависимости от причины открытия модалки авторизации выполняем дальнейшие действия
   */
  handleModalOpenReason(modalMeta) {
    const reason = modalMeta.meta.reason

    switch (reason) {
      case AUTH_MODAL_TYPES[AUTH_MODAL_TYPE_NAMES.CART]:
        // пушим на шину событие логина, запуская таким образом дальнейшую логику перехода на чекаут
        this.publishLoginEventWithoutRedirect(modalMeta)
        break
      case AUTH_MODAL_TYPES[AUTH_MODAL_TYPE_NAMES.PRODUCTS]:
      case AUTH_MODAL_TYPES[AUTH_MODAL_TYPE_NAMES.BRANDS]:
      default:
        // редиректим на страницу, с которой уходили на внешний сервис авторизации
        this.publishLoginEventWithRedirect(modalMeta)
    }
  }

  /**
   * Метод подтверждения кода внешнего сервиса авторизации
   *
   * @param {object} obj
   * @param {string} obj.code - код подтверждения внешнего сервиса авторизации
   * @param {string} obj.service - имя сервиса авторизации
   *
   * @returns {object} response | error
   */
  async loginViaExternalService(service) {
    const reqType = 'form'

    const grantType = GRANT_TYPE[service] ?? undefined

    const modalMeta = this.prepareModalMeta()

    if (!grantType) {
      throw new Error('There is no such authorization service among possible')
    }

    const path =
      this.gaApp.services.auth.localStorage.getLocalStorageAuthRedirectUrl() ??
      '/'

    try {
      const { query } = this.gaApp.route

      const data =
        this.gaApp.services.auth.externalAuthBuilder.buildRequestPayloadByService(
          service,
          query,
        )

      const config = buildContentTypeHeader(reqType)
      const response = await this.gaApp.services.auth.api.login(data, config)

      if (response.data) {
        // eslint-disable-next-line camelcase
        const { magento_data = {} } = response.data

        // Запрашиваем данные юзера и устанавливаем
        await this.gaApp.services.user.api.fetchUserInfoFull()

        // Устанавливаем для magento данные в localStorage
        // eslint-disable-next-line camelcase
        this.gaApp.services.cache.main.saveUser(magento_data.customer_section)
      }

      this.handleModalOpenReason(modalMeta)

      // очищаем local storage
      this.gaApp.services.auth.localStorage.removeLocalStorageAuthRedirectUrl()
      this.gaApp.services.auth.localStorage.removeLocalStorageAuthModalOpenReason()

      return response
    } catch (error) {
      // при ошибке редиректим на страницу, с которой иницировали авторизацию, открываем форму авторизации,
      // показываем сообщение об ошибке
      this.gaApp.services.app.router.push(`${path}#auth`, () => {
        const message =
          error.data?.data?.localizedMessage ??
          this.gaApp.i18n.t(`auth.error.externalAuthFailed.${service}`) ??
          this.gaApp.i18n.t('auth.error.externalAuthFailed.default')

        this.gaApp.services.notification.main.open(message)
      })
    }
  }

  /**
   * Метод отправляет запрос на выход из системы
   *
   * @returns {object} response | error
   */
  async logout() {
    const reqType = 'form'
    const data = buildRequestPayload('logout', reqType)
    const config = buildContentTypeHeader(reqType)

    this.gaApp.services.auth.core.resetTokens()

    const response = await this.gaApp.services.auth.api.logout(data, config)

    this.gaApp.eventBus.publish('module/auth/logout')

    return response
  }

  async logoutWithClearData() {
    await this.logout()
    this.gaApp.services.user.main.resetUser()
    this.gaApp.services.auth.modal.reset()
  }

  onAuthFailed(redirect, to) {
    // сбрасываем стор модалки авторизации
    this.gaApp.services.auth.modal.reset()

    // сохраняем redirect URL в local storage
    if (this.gaApp.features.get('authorizationTypesEnabled')) {
      this.gaApp.services.auth.localStorage.setLocalStorageAuthRedirectUrl(
        to.path,
      )
    }

    window.requestAnimationFrame(() => {
      const payload = redirect
        ? {
            redirect: 'page',
            callbacks: { redirect },
          }
        : {}

      // Открываем модалку
      window.requestAnimationFrame(() => {
        this.gaApp.services.modal.main.openSuperModalAuth({
          reason: AUTH_MODAL_REASON_TO_OPEN.RELOGIN,
          ...payload,
        })

        // Если модалка не открылась, то очищаем юзера
        this.gaApp.ctx.$doubleRAF(() => {
          if (!this.gaApp.stores.modal.main.hasOpenedAuthModal) {
            this.gaApp.services.user.main.resetUserAndRefresh()
          }
        })
      })
    })
  }

  openModal(settings) {
    const payload = { push: true, ...settings }

    this.gaApp.services.auth.modal.reset()

    this.gaApp.services.modal.main.openSuperModalAuth(payload)
  }

  async checkRouteAuth(to, _, next) {
    const onAccessDenied = () => {
      next(false)

      this.gaApp.services.auth.core.resetTokens()
      this.gaApp.services.auth.main.onAuthFailed(next, to)
    }

    // Если это роут не требующий авторизации,
    // то просто пропускаем юзера
    if (!this.gaApp.services.app.router.isAuthRequired(to.path)) {
      return next()
    }

    if (!this.isAuthorized) {
      return onAccessDenied()
    }

    // Если юзер авторизован, то проверяем его токены
    const { hasAccessToken, hasRefreshToken } =
      this.gaApp.services.auth.core.checkTokens()

    try {
      // Если нет access'a, но есть рефреш, то пробуем рефрешнуть
      if (!hasAccessToken && hasRefreshToken) {
        await this.gaApp.services.auth.core.doRefreshTokens()

        await this.gaApp.services.user.api.fetchUserInfoFull()

        return next()
      }

      // Если нет ни access'a, ни refresh'a, то не пропускаем юзера
      if (!hasAccessToken && !hasRefreshToken) {
        return onAccessDenied()
      }

      // Запросили полное инфо юзера
      await this.gaApp.services.user.api.fetchUserInfoFull()

      return next()
    } catch (error) {
      return onAccessDenied()
    }
  }

  /**
   * При авторизаци передаем данные для мержка сессии гостя и пользователя
   */
  getUserData() {
    const cartId = this.gaApp.stores.cart.main.id
    const { id } = this.gaApp.services.location.main.getDeliveryAddress()

    if (this.gaApp.stores.app.common.toggle.addressBlockEnabled) {
      return {
        'X-Magento-Auth-Data': encode(
          JSON.stringify({
            ...(cartId ? { quoteId: cartId } : {}),
          }),
        ),
      }
    } else {
      return {
        'X-Magento-Auth-Data': encode(
          JSON.stringify({
            ...(cartId ? { quoteId: cartId } : {}),
            ...(id ? { geolocationId: id } : {}),
          }),
        ),
      }
    }
  }
}
