/**
 * Утилита чтобы правильно получать id из адреса
 * @param { object } address - адрес
 * @returns {{ saved: string, mixed: string }}
 */
export const getAddressId = (address) => {
  // у временного адреса нет saveId, а addressId может быть не уникальным, поэтому
  // для сохраненных + временного используем addressId + saveId
  // для сохраненных достаточно saveId

  // на данный момент:
  // уточнение адреса (если кликнуть на адрес в хедере) - mixed
  // ЛК - saved
  // Чекаут - saved, но планируется перейти на mixed
  return {
    mixed: address.addressId + (address.saveId || ''),
    saved: address.saveId || '',
  }
}
